import React, { Component } from 'react';
import { observer } from 'mobx-react';
import './style.scss';
import t from 'i18n';

import EventsSmallStore from './store';
import { AutoAnimate, CalendarSlider, EventCard } from 'components';
import { observable } from 'mobx';
import store from 'client/store';
import { Link } from 'react-router-dom';
import Portal from 'img/icon-all_sites.svg';

@observer
export default class Poster extends Component {
	@observable currentPage = 1;

	constructor(props) {
		super(props);
		this.store = new EventsSmallStore();
	}

	render() {
		const itemsPerPage = 8;
		const totalItems = this.store.events.length;
		const totalPages = Math.ceil(totalItems / itemsPerPage);

		const itemsToDisplay = this.store.events.slice((this.currentPage - 1) * itemsPerPage, this.currentPage * itemsPerPage);

		const handleClick = (e) => {
			let value = e.target.getAttribute('data-value');
			if (value !== null) {
				this.selected = value;
			}
		};

		const pagination = Array.from({ length: totalPages }, (_, index) => index + 1).map((pageNumber) => (
			<li
				className={`events-pagination-item ${this.currentPage === pageNumber ? 'active' : ''}`}
				key={pageNumber}
				onClick={() => {
					this.currentPage = pageNumber;
					handleClick;
				}}
			/>
		));

		const { changeDate, selectedDate, events } = this.store;

		return (
			<div className='poster'>
				<div className='poster-header'>
					<span>{t('poster.title')}</span>
					<Link to={'/events'} alt={t('sites.events')}>
						<Portal />
						{t('searchPage.goToSection')}
					</Link>
				</div>
				<div className='poster-content'>
					<CalendarSlider changeDate={changeDate} selectedDate={selectedDate} dateCount={22} />
					<div className='events-container'>
						<AutoAnimate className='events-items-container'>
							{!events.length && <div>{t('events.noEvents')}</div>}
							{itemsToDisplay.map((item) => (
								<EventCard record={item} key={item.id} onClick={() => store.route.push({ path: `/events/${item.id}` })} />
							))}
						</AutoAnimate>
						{events.length > 8 && <div className='pagination-container'>{pagination}</div>}
					</div>
				</div>
			</div>
		);
	}
}
