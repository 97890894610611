import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import store from 'client/store';
import './style.scss';
import { JPEG_QUALITY } from 'constants';
import { t } from 'i18next';

@observer
export default class Banner extends React.Component {
	@observable banner = null;
	@observable isLoading = true;

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		// const banners = store.ui.banners.filter((item) => item.width === 894 && item.height === 280);
		// this.banner = banners.length > 0 ? banners[0] : null;
		const banners = await store.model.Slide.find({
			where: { type: null },
			order: 'id desc',
		});
		this.banner = banners.length > 0 ? banners[0] : null;
		this.isLoading = false;
	};

	render() {
		if (this.isLoading) return null;
		if (!this.banner || (!this.banner.filename && !this.banner.html)) return null;

		const img = (
			<img
				src={this.banner.downloadFile('filename' + `?image=[{"resize":{"width":447, "height":280}}]`)}
				style={{ width: 447, height: 280 }}
				alt={this.banner.filename}
			/>
		);
		const link = this.banner.link;
		const html = this.banner.html;
		const className = classNames(`banner-894x280`, `place-0`, {
			linked: !!link,
		});
		console.log(this.banner);
		return (
			<div className='top-banner-container'>
				<div className={className}>
					<div className='text-block'>
						{this.banner.header && <div className='header h4'>{this.banner?.header}</div>}
						{this.banner?.description && <div className='description p1'>{this.banner?.description}</div>}
						<div className='text-block-button-container'>
							{link ? (
								<Link to={link} alt={t('goTo')}>
									<button className='text-block-button p1'>{t('goTo')}</button>
								</Link>
							) : (
								<button className='text-block-button p1'>Перейти</button>
							)}
						</div>
					</div>
					{html ? (
						<div dangerouslySetInnerHTML={{ __html: html }} />
					) : link ? (
						/^http/.test(link) ? (
							<a target='_blank' href={link} alt={this.banner.filename}>
								{img}
							</a>
						) : (
							<Link to={link} alt={this.banner.filename}>
								{img}
							</Link>
						)
					) : (
						img
					)}
				</div>
			</div>
		);
	}
}
