import React from 'react';
import './style.scss';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { Desktop, Mobile, NotPhone, Phone, SocialSinglePage, SupportMeasureSmall } from '../../../components';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import allSitesIcon from 'client/img/icon-all_sites-grey.svg?url';
import ExternalLinkIcon from 'client/img/icon-external-link.svg';
import arrowLeft from 'client/img/icon-arrow_left-black.svg?url';
import arrowRight from 'client/img/icon-arrow_right.svg?url';
import { observable } from 'mobx';
import store from 'client/store';
import { t } from 'i18next';
import iconUpload from 'img/icon-upload.svg?url';
import SupportMeasureStore from 'client/pages/support-measure/store';
import Poll from 'client/pages/support-measure/single/Poll';
import { Button } from 'components';
import ExternalLink from 'helpers/externalLink';
import { SvgIcon } from 'components/svgIcon';
import { MetaInfo } from 'components/meta-info';

@withRouter
@observer
export default class Single extends React.Component {
	@observable record = null;
	@observable smallSupportMeasureItems = null;
	@observable error = null;
	@observable isLoading = true;
	constructor(props) {
		super(props);
		this.init();
		this.store = new SupportMeasureStore();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.match.params.id !== prevProps.match.params.id || this.props.id !== prevProps.id) {
			this.init();
		}
	}

	init = async () => {
		this.id = this.props.match.params.id;
		if (this.id) {
			try {
				this.record = await store.model.SupportMeasure.findById(this.id, {
					include: ['labels', 'topics', 'attachments'],
				});
				this.smallSupportMeasureItems = await store.model.SupportMeasure.find();
			} catch (e) {
				this.error = e.message;
			}
		} else {
			this.error = 'Страница не найдена';
		}
		this.isLoading = false;
	};

	render() {
		if (!this.record) return null;

		const attachments = this.record?.attachments();
		const labels = this.record?.labels();
		const forwardRec = this.smallSupportMeasureItems?.map((rec) => rec);
		const tmpArr = [];
		for (let i = 0; i < forwardRec?.length; i++) {
			tmpArr.push(forwardRec[i]?.title);
		}
		let curId = tmpArr.indexOf(this.record?.title);

		return (
			<div className='page-content-single support-measure-single-container'>
				<div className='support-measure-single-page'>
					<Phone>
						<Link to='/support-measure' className='wrapper-back' alt={t('supportMeasure.title')}>
							<SvgIcon name='left-arrow' />
							{t('supportMeasure.title')}
						</Link>
					</Phone>
					<NotPhone>
						<div className='support-measure-head'>
							<div className='category-subcategory'>
								<Link to='/support-measure' alt={t('supportMeasure.title')}>
									<div className='category date p2'>{t('supportMeasure.title')}</div>
								</Link>
								<div className='subcategory date p2'>{this.record?.title}</div>
							</div>
							<div className='to-portal'>
								<img src={allSitesIcon} alt={t('goPortal')} />
								<Link to={'/'} alt={t('goPortal')}>
									<div className='go-portal p4'>{t('goPortal')}</div>
								</Link>
							</div>
						</div>
					</NotPhone>

					<div className='support-measure-single-page-item'>
						<div className='item-title h1'>{this.record?.title}</div>
						<div className='item-topic'>{this.record?.topics.name}</div>
						<div className='description-title h5'>{t('supportMeasure.descriptionMeasure')}</div>
						<div className='description' dangerouslySetInnerHTML={{ __html: this.record?.description.replace(/(<([^>]+)>)/gi, '') }} />
						{this.record?.buttonLink && this.record?.buttonText && (
							<ExternalLink to={this.record && this.record.buttonLink} alt={this.record.buttonText}>
								<Button className='item-button' prependIcon={<ExternalLinkIcon />} color='deep-blue'>
									{this.record && this.record.buttonText}
								</Button>
							</ExternalLink>
						)}
						{attachments?.totalCount > 0 && (
							<>
								<div className='additional-info-title'>{t('supportMeasure.additionalInfo')}</div>
								<div className='app p4'>{t('application')}</div>
								<div className='item-application'>
									{attachments &&
										attachments?.map((a) => (
											<a className='docs' target='_blank' key={a.id} href={a?.downloadFile('filename')} download alt={a?.filename}>
												<div className='file-name p2'>{a?.filename}</div>
												<img src={iconUpload} alt={t('download')} />
											</a>
										))}
								</div>
							</>
						)}
						<div className='evaluate-measure-title'>{t('supportMeasure.evaluateMeasure')}</div>
						<div className='useful-info-choice'>
							<div className='useful-info-title'>{t('supportMeasure.usefulInfo')}</div>
							<div className='button-contanier'>
								<Poll record={this.record} />
							</div>
						</div>
						<div className='labels-share'>
							<div className='labels p2'>{labels && labels.map((rec) => ' #' + rec.name)}</div>
							<div className='social-share'>
								<div>{t('share')}</div>
								<div className='social-bottom'>
									<SocialSinglePage title={this.record?.title} />
								</div>
							</div>
						</div>
						<MetaInfo record={this.record} />
						<div className='back-forward'>
							<Link to={'/support-measure'} alt={t('backToSection')}>
								<div className='back'>
									<img src={arrowLeft} className='arrow-left' alt={t('backToSection')} />
									<span className='p4'>{t('backToSection')}</span>
								</div>
							</Link>
							{forwardRec && (
								<Link to={`/support-measure/${this.record.id + 1}`} alt={forwardRec[curId + 1]?.title}>
									<div className='forward'>
										<span className='p4'>{forwardRec[curId + 1]?.title}</span>
										{forwardRec[curId + 1]?.title && <img src={arrowRight} className='arrow-right' alt={forwardRec[curId + 1]?.title} />}
									</div>
								</Link>
							)}
						</div>
					</div>
				</div>
				{/* SideBar */}
				<div className='sidebar'>
					<div className='sidebar-support-measure-small'>
						<span className='p4'>{t('interesting')}</span>
						<span className='line'></span>
					</div>
					{this.smallSupportMeasureItems &&
						this.smallSupportMeasureItems.map((items) => {
							return <SupportMeasureSmall key={items.id} records={items} />;
						})}
				</div>
			</div>
		);
	}
}
