import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import store from 'client/store';
import './style.scss';
import { Tooltip, Picture } from 'components';

@observer
export default class SlideSmall extends React.Component {
	render() {
		const { record } = this.props;
		if (!record || (!record.image && !record.html)) return null;

		const link = record && record.link;
		const html = record && record.html;
		const className = classNames(`sliders-small`, `place-0`, {
			linked: !!link,
		});

		const img = <Picture src={record.downloadFile('image')} width={342} alt={record.image} />;

		return (
			<>
				<div className='small-top-slide-container'>
					<div className={className}>
						<Tooltip style={{ margin: '15px', width: '150px', WebkitLineClamp: '5' }} position='bottom' content={record.description}>
							{html ? (
								<div dangerouslySetInnerHTML={{ __html: html }} />
							) : link ? (
								/^http/.test(link) ? (
									<a target='_blank' href={link} alt={record.image}>
										{img}
									</a>
								) : (
									<Link to={link} alt={record.image}>
										{img}
									</Link>
								)
							) : (
								img
							)}
						</Tooltip>
					</div>
				</div>
			</>
		);
	}
}
