import { observable } from 'mobx';
import store from 'client/store';
import { getModelWhere } from 'helpers';

export default class QuestionAnswerStore {
	@observable records = [];
	@observable categories = [];
	@observable category = null;

	constructor() {
		this.init();
	}

	onCategoryChange = (category) => {
		this.category = category;
		this.fetchRecords();
	};

	init = async () => {
		const where = getModelWhere(store.model.QuestionAnswer, false);
		const categories = await store.model.QuestionAnswerCategory.find({
			fields: ['id', 'name'],
			include: [{ relation: 'questions', scope: { fields: ['id', 'branchCategoryId', 'siteId', 'organizationId', 'languageId', 'publish'], limit: 1, where } }],
		});
		this.categories = categories.filter((r) => !!r.questions().length);

		const poll = await store.model.ViewDiscussion.find({
			where: {
				startDate: { lt: Date.now() },
				showMain: true,
				siteDomain: store.ui.site.domain,
			},
			order: 'id DESC',
			limit: 1,
		});

		this.poll = poll[0];
		await this.fetchRecords();
	};

	fetchRecords = async () => {
		const where = getModelWhere(store.model.ViewQuestionAnswer, false);
		if (this.category) where.and.push({ branchCategoryId: this.category.id });
		this.records = await store.model.ViewQuestionAnswer.find({
			where,
			include: [
				{ relation: 'branchCategory', scope: { fields: ['id', 'name'] } },
				{ relation: 'subCategory', scope: { fields: ['id', 'name'] } },
			],
			fields: ['id', 'title', 'description', 'image', 'publishAt', 'branchCategoryId', 'subCategoryId'],
			order: 'publishAt asc',
			limit: 5,
		});
	};
}
