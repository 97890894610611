import React, { Component } from 'react';
import './style.scss';
import store from 'client/store';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { formatDate, getModelWhere } from 'client/helpers';
import { Link } from 'react-router-dom';
import t from 'i18n';

@observer
export default class VacancyWidget extends Component {
	@observable record = null;

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		const model = store.model.Vacancy;
		const isMainShowAll = false;

		if (store.domain === 'sakhagov.smartplatform.pro') {
			this.record = await model.find();
		} else {
			const where = getModelWhere(model, isMainShowAll);
			this.record = await model.find({
				where,
			});
		}
	};
	render() {
		return (
			<>
				<div className='vacancy-widget-container'>
					<div className='header h6'>{t('vacancy.lastVacancies')}</div>
					<div className='vacancy-widget'>
						{this.record &&
							this.record
								.map((rec) => {
									return (
										<Link to={`/vacancy/${rec.id}`} key={rec.id} alt={rec?.title}>
											<div className='vacancy-item'>
												<div className='vacancy-item-title p2'>{rec?.title}</div>
												<div className='vacancy-item-contact-person p3'>{rec?.contactPerson}</div>
												<div className='vacancy-item-created-at p3'>{formatDate(rec?.createdAt, 'dd.MM.yyyy')}</div>
											</div>
										</Link>
									);
								})
								.slice(0, 4)}
					</div>
					<a href='/vacancy' alt={t('vacancy.showAllVacancies')}>
						<button className='vacancy-widget-button'>
							<p>{t('vacancy.showAllVacancies')}</p>
						</button>
					</a>
				</div>
			</>
		);
	}
}
