import React from 'react';
import TelegramIcon from 'client/img/social/icon-telegram.svg';
import VKIcon from 'client/img/social/icon-vk.svg';
import RssIcon from 'client/img/social/rss.svg';
import Twitter from 'client/img/social/twitter-2.svg';
import Rutube from 'client/img/social/icon-rutube.svg';
import Odnoklassniki from 'client/img/social/icon-odnoklassniki.svg';
import { t } from 'i18next';

import './style.scss';
import store from 'client/store';

export default ({ className }) => {
	const { telegram, vkontakte, rutube, odnoklassniki, twitter, domain } = store.ui.site;

	return (
		<div className={'social-block ' + (className || '')}>
			{telegram && (
				<a target='_blank' href={telegram} alt={t('alt.telegram')}>
					<TelegramIcon />
				</a>
			)}
			{vkontakte && (
				<a target='_blank' href={vkontakte} alt={t('alt.vk')}>
					<VKIcon />
				</a>
			)}
			{twitter && (
				<a target='_blank' href={twitter} alt={t('alt.twitter')}>
					<Twitter />
				</a>
			)}
			{rutube && (
				<a target='_blank' href={rutube} alt={t('alt.rutube')}>
					<Rutube />
				</a>
			)}
			{odnoklassniki && (
				<a target='_blank' href={odnoklassniki} alt={t('alt.odnoklassniki')}>
					<Odnoklassniki />
				</a>
			)}
			<a target='_blank' href={`https://${domain}/rss.xml`} alt={t('alt.rss')}>
				<RssIcon />
			</a>

			{/* Ниже привязка ссылок на сети выбранного субпортала */}
			{/*{magazine.twitter && <a target="_blank" href={magazine.twitter}><TwitterIcon /></a>}*/}
		</div>
	);
};
