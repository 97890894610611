import React, { Component } from 'react';
import store from 'client/store';
import t from 'i18n';
import { observer } from 'mobx-react';
import { Form, RadioInput, Field, DatePicker } from '@smartplatform/ui';
import { observable } from 'mobx';
import RatingStar from 'components/rating-star/RatingStar';
import { EXECUTED_ITEMS, RESULT_ITEMS, TERM_ITEMS } from 'client/pages/citizens-appeals/constants';

@observer
class FormEvaluationOfResults extends Component {
	@observable record = null;
	@observable isLoading = true;

	constructor(props) {
		super(props);
		store.ui.title = t('citizensAppeals.formEvaluationOfResults');
		this.init();
	}

	init = async () => {
		this.id = parseInt(this.props.match.params.id);
		this.record = new store.model.CitizenAppealResult();
		this.appeal = await store.model.CitizenAppeal.findById(this.id);
		this.isLoading = false;
	};

	onSave = () => {
		this.appeal.resultId = this.record.id;
		this.appeal.save();
		store.route.push({ path: '/' });
	};

	render() {
		if (this.isLoading) return null;
		return (
			<div className='page-content-single form-evaluation-container'>
				<div className='form-evaluation'>
					<div className='p1'>{t('citizensAppeals.answerTheQuestions')}</div>
					<div className='evaluation-form'>
						<Form record={this.record} onSave={this.onSave}>
							<div className='conclusion'>
								<div className='header p2'>{t('citizensAppeals.whatDecisionWasMade')}</div>
								<Field property='result'>
									<RadioInput items={RESULT_ITEMS} name={'result'} alt={t('citizensAppeals.whatDecisionWasMade')} />
								</Field>
							</div>
							<div className='yes-no'>
								<div className='header p2'>{t('citizensAppeals.hasTheDeadline')}</div>
								<Field property='term'>
									<RadioInput items={TERM_ITEMS} name={'term'} alt={t('citizensAppeals.whatDecisionWasMade')} />
								</Field>
							</div>
							<div className='date'>
								<div className='header p2'>{t('citizensAppeals.specifyDeadline')}</div>
								<Field property='specifyDeadline'>
									<DatePicker placeholder={t('date')} alt={t('citizensAppeals.whatDecisionWasMade')} />
								</Field>
							</div>
							<div className='decision'>
								<div className='header p2'>{t('citizensAppeals.hasTheDecisionMade')}</div>
								<Field property='executed'>
									<RadioInput items={EXECUTED_ITEMS} name={'executed'} alt={t('citizensAppeals.whatDecisionWasMade')} />
								</Field>
							</div>
							<div className='rating'>
								<div className='header p2'>{t('citizensAppeals.evaluateTheWork')}</div>
								<Field property='evaluation'>
									<RatingStar record={this.record} alt={t('citizensAppeals.evaluateTheWork')} />
								</Field>
							</div>
						</Form>
					</div>
				</div>
			</div>
		);
	}
}

export default FormEvaluationOfResults;
