import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import './style.scss';
import PropTypes from 'prop-types';

@observer
export default class RatingStar extends React.Component {
	@observable rating = 0;

	static propTypes = {
		record: PropTypes.object,
	};

	constructor(props) {
		super(props);
	}

	handleRatingChange = async (value) => {
		this.rating = value;
		this.props.record.evaluation = value;
		await this.props.record.save();
	};

	renderRating = () => {
		const stars = [];

		for (let i = 0; i < 5; i++) {
			let color = '--grey-1';
			if (i < this.rating) {
				color = '--deep-blue';
			}
			stars.push(
				<svg
					width='36'
					height='36'
					viewBox='0 0 36 36'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
					key={i}
					className='star'
					onClick={() => this.handleRatingChange(i + 1)}
				>
					<g id='material-symbols:star-outline-rounded'>
						<path
							id='Vector'
							d='M13.2745 26.7376L17.9995 23.8876L22.7245 26.775L21.487 21.375L25.6495 17.775L20.1745 17.2875L17.9995 12.1875L15.8245 17.25L10.3495 17.7375L14.512 21.375L13.2745 26.7376ZM17.9995 27.4125L11.7745 31.1625C11.4995 31.3375 11.212 31.4125 10.912 31.3875C10.612 31.3625 10.3495 31.2626 10.1245 31.0876C9.89952 30.9126 9.72452 30.6936 9.59952 30.4305C9.47452 30.1676 9.44952 29.8741 9.52452 29.5501L11.1745 22.4625L5.66202 17.7C5.41202 17.475 5.25602 17.2185 5.19402 16.9305C5.13202 16.6425 5.15052 16.3615 5.24952 16.0875C5.34952 15.8125 5.49952 15.5875 5.69952 15.4125C5.89952 15.2375 6.17452 15.125 6.52452 15.075L13.7995 14.4375L16.612 7.76255C16.737 7.46255 16.931 7.23755 17.194 7.08755C17.457 6.93755 17.7255 6.86255 17.9995 6.86255C18.2745 6.86255 18.543 6.93755 18.805 7.08755C19.067 7.23755 19.261 7.46255 19.387 7.76255L22.1995 14.4375L29.4745 15.075C29.8245 15.125 30.0995 15.2375 30.2995 15.4125C30.4995 15.5875 30.6495 15.8125 30.7495 16.0875C30.8495 16.3625 30.8685 16.644 30.8065 16.932C30.7445 17.22 30.588 17.476 30.337 17.7L24.8245 22.4625L26.4745 29.5501C26.5495 29.875 26.5245 30.1691 26.3995 30.432C26.2745 30.695 26.0995 30.9136 25.8745 31.0876C25.6495 31.2626 25.387 31.3625 25.087 31.3875C24.787 31.4125 24.4995 31.3375 24.2245 31.1625L17.9995 27.4125Z'
							fill={`var(${color})`}
						/>
					</g>
				</svg>
			);
		}

		return stars;
	};

	render() {
		return (
			<div className='rating-star' alt={this.props.alt}>
				{this.renderRating()}
			</div>
		);
	}
}
