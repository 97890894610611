import React, { Component } from 'react';
import './style.scss';
import { observer, Provider } from 'mobx-react';
import SupportMeasureStore from '../store';
import { Loader, Tab, Tabs } from '@smartplatform/ui';
import { t } from 'i18next';
import { observable } from 'mobx';
import store from 'client/store';
import { NotPhone, Phone, SupportMeasureSmall, AutoAnimate, ShowMoreButton } from 'components';
import { Link } from 'react-router-dom';
import { SearchPage } from 'components/search-page';

@observer
export default class SupportMeasure extends Component {
	@observable labels = [];
	@observable topics = [];
	@observable search = '';
	@observable recordFiltered = [];
	@observable records = [];
	@observable allTopics = [];
	topicCodeParam = null;

	constructor(props) {
		super(props);
		this.store = new SupportMeasureStore();
		this.topicCodeParam = props.match.params.topicCode;
		this.supportMeasureLabels();
		this.supportMeasureTopics();
	}

	componentDidUpdate() {
		this.records = this.store.records;
	}

	supportMeasureLabels = async () => {
		this.labels = await store.model.SupportMeasureLabel.find({
			includes: [{ relation: 'labels', scope: { fields: ['id', 'name'] }, limit: 10 }],
		});
	};

	supportMeasureTopics = async () => {
		this.allTopics = [];
		this.topics = await store.model.SupportMeasure.find({
			include: [{ relation: 'topics', scope: { fields: ['id', 'name', 'code'] } }],
		});
		this.allTopics = Array.from(new Set(this.topics.map((rec) => rec.topics))).filter(Boolean);
		if (this.topicCodeParam) {
			const topic = this.allTopics.find((topic) => topic.code === this.topicCodeParam);
			if (topic) {
				this.store.onChangeFilter('topic', topic);
			} else {
				this.store.init();
			}
		} else {
			this.store.init();
		}
	};

	handleSearchQuery = (record) => {
		this.search = record.toLowerCase();
		this.filterRecords();
	};

	filterRecords = () => {
		this.recordFiltered = this.records.filter((data) => data.title.toLowerCase().includes(this.search));
	};

	searchFilterItems = () => {
		return this.store.records;
	};

	resetAllTags = () => {
		this.store = new SupportMeasureStore();
		this.records = [];
		this.records = this.store.records;
	};

	tabsRender = () => {
		const { setTab, onChangeFilter } = this.store;
		return (
			<Tabs type='manual'>
				<Tab title={t('supportMeasure.all')} onClick={() => setTab('all')} />
				{this.allTopics.map((topic, index) => {
					return (
						<Tab
							title={topic?.name}
							key={index}
							onClick={() => onChangeFilter('topic', topic)}
							initial={this.topicCodeParam && this.topicCodeParam === topic.code}
						/>
					);
				})}
			</Tabs>
		);
	};

	render() {
		if (!this.allTopics.length) return null;

		const { tab, onChangeFilter, isLoading, totalCountSupportMeasure, showMoreSupportMeasure, recordsPopular } = this.store;

		// убираем повторяющиеся теги для sidebar
		const tags = [...new Set(this.labels.map((r) => r?.label))];

		return (
			<Provider store={this.store}>
				<>
					<SearchPage
						headerName={t('supportMeasure.title')}
						placeholder={t('supportMeasure.searchBySupportMeasure')}
						search={this.search}
						onChangeSearch={(r) => this.handleSearchQuery(r)}
					/>
					<div className='support-measure-page'>
						<div className='support-measure-container'>
							<div className='support-measure-item'>
								<NotPhone>{this.tabsRender()}</NotPhone>
								{tab === 'all' && (
									<AutoAnimate className='support-measure-all'>
										{!isLoading && this.search === '' && this.records
											? this.records.map((rec) => (
													<Link to={`/support-measure/${rec.id}`} key={rec.id} alt={rec?.title}>
														<div className='support-measure-items'>
															<div className='topic'>{rec.topics?.name}</div>
															<div className='title'>{rec?.title}</div>
															<div className='description' dangerouslySetInnerHTML={{ __html: rec?.description?.replace(/(<([^>]+)>)/gi, '') }} />
														</div>
													</Link>
												))
											: this.recordFiltered &&
												this.recordFiltered.map((rec) => (
													<Link to={`/support-measure/${rec.id}`} key={rec.id} alt={rec?.title}>
														<div className='support-measure-items'>
															<div className='topic'>{rec.topics?.name}</div>
															<div className='title'>{rec?.title}</div>
															<div className='description' dangerouslySetInnerHTML={{ __html: rec?.description.replace(/(<([^>]+)>)/gi, '') }} />
														</div>
													</Link>
												))}
									</AutoAnimate>
								)}
								{this.records.totalCount > 6 && (
									<ShowMoreButton
										isLoading={isLoading}
										records={this.records}
										totalCount={totalCountSupportMeasure}
										showMore={showMoreSupportMeasure}
										formsArray={[t('supportMeasure.formsArrayFirst'), t('supportMeasure.formsArraySecond'), t('supportMeasure.formsArrayThird')]}
									/>
								)}
							</div>

							{/* SideBar */}
							<div>
								<div className='sidebar'>
									<div className='sidebar-support-measure'>
										<span className='p4'>{t('tag.plural')}</span>
										<span className='line'></span>
									</div>
									<div className='tags-container'>
										{tags &&
											tags.map((tag) => (
												<div className='tags p1' key={tag?.id} onClick={() => onChangeFilter('label', tag)}>
													{'#' + tag?.name}&nbsp;
												</div>
											))}
									</div>
									<div className='right p1' onClick={this.resetAllTags}>
										{t('tag.all')}
									</div>
								</div>
								<div className='sidebar'>
									<div className='sidebar-support-measure'>
										<span className='p4'>{t('popular')}</span>
										<span className='line'></span>
									</div>
									<div className='popular-support-measure'>
										{recordsPopular &&
											recordsPopular.map((rec) => {
												return <SupportMeasureSmall key={rec.id} records={rec} />;
											})}
									</div>
								</div>
							</div>

							{/* Tabs mobile */}
							<Phone>{this.tabsRender()}</Phone>
						</div>
					</div>
				</>
			</Provider>
		);
	}
}
