import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { Button } from 'components';
import store from 'client/store';
import t from 'i18n';

import { Select } from '@smartplatform/ui';

const reason = {
	0: 'Вопрос по рекламе',
	1: 'Предложить новость',
	2: 'Обращение правообладателя',
	3: 'Ошибка, опечатка на сайте',
};

@withRouter
@observer
export default class Form extends React.Component {
	@observable name = '';
	@observable email = '';
	@observable tel = '';
	@observable reason = 0;
	@observable title = '';
	@observable text = '';
	@observable error = null;
	@observable isSubmitting = false;

	constructor(props) {
		super(props);
	}

	submit = async (evt) => {
		evt.preventDefault();
		this.error = null;
		if (this.name.trim().length === 0) {
			this.error = t('feedback.emptyAuthor');
			return;
		}
		if (this.email.trim().length === 0) {
			this.error = t('feedback.emptyEmail');
			return;
		}
		if (this.title.trim().length < 3) {
			this.error = t('feedback.emptyTitle');
			return;
		}
		if (this.text.trim().length < 3) {
			this.error = t('feedback.emptyText');
			return;
		}
		try {
			this.isSubmitting = true;

			const feedback = new store.model.Feedback();
			feedback.name = this.name;
			feedback.email = this.email;
			feedback.tel = this.tel;
			feedback.reason = this.reason;
			feedback.title = this.title;
			feedback.text = this.text;
			await feedback.save();

			this.name = '';
			this.email = '';
			this.tel = '';
			this.reason = null;
			this.title = '';
			this.text = '';
			this.isSubmitting = false;

			this.props.history.push(`/feedback/${feedback.id}`);
		} catch (e) {
			this.error = e.message;
		}
	};

	onNameChange = (e) => (this.name = e.target.value);
	onEmailChange = (e) => (this.email = e.target.value);
	onTelChange = (e) => (this.tel = e.target.value);
	onReasonChange = (e) => {
		for (let reasonKey in reason) {
			if (reason[reasonKey] === e) {
				this.reason = reasonKey;
			}
		}
	};
	onTitleChange = (e) => (this.title = e.target.value);
	onTextChange = (e) => (this.text = e.target.value);

	render() {
		return (
			<div className='page-content-single feedback-form'>
				<div className='page-title'>
					<h1>Обратная связь</h1>
				</div>
				<form className='feedback'>
					<div className='block half-block'>
						<label>
							{t('fio')}
							<span>*</span>:
						</label>
						<input value={this.name} onChange={this.onNameChange} type='text' alt={t('fio')} />
					</div>
					<div className='block half-block'>
						<label>
							{t('email.title')}
							<span>*</span>:
						</label>
						<input value={this.email} onChange={this.onEmailChange} type='email' alt={t('email.title')} />
					</div>
					<div className='block half-block'>
						<label>{t('phoneNumber')}:</label>
						<input value={this.tel} onChange={this.onTelChange} type='text' alt={t('phoneNumber')} />
					</div>
					<div className='block half-block'>
						<label>
							{t('reasonFeedback')}
							<span>*</span>:
						</label>
						<Select
							items={Object.values(reason)}
							showValue={reason[this.reason]}
							onChange={this.onReasonChange}
							isRequired
							className='reason-select'
							alt={t('reasonFeedback')}
						/>
					</div>
					<div className='block'>
						<label>
							{t('themeFeedback')}
							<span>*</span>:
						</label>
						<input value={this.title} onChange={this.onTitleChange} type='text' alt={t('themeFeedback')} />
					</div>
					<div className='block'>
						<label>
							{t('messageFeedback')}
							<span>*</span>:
						</label>
						<textarea value={this.text} onChange={this.onTextChange} alt={t('messageFeedback')} />
					</div>

					{/*TODO: Добавить каптчу*/}

					<div className='actions'>
						<Button
							onClick={this.submit}
							className='btn-md btn-primary'
							disabled={
								this.isSubmitting ||
								this.name.trim().length === 0 ||
								this.email.trim().length === 0 ||
								this.title.trim().length < 3 ||
								this.text.trim().length < 3
							}
							alt={t('feedback.post')}
						>
							{t('feedback.post')}
						</Button>
					</div>
					{this.error && <div className='error'>{this.error}</div>}
				</form>
			</div>
		);
	}
}
