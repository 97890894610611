import React, { Component } from 'react';
import './style.scss';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { t } from 'i18next';
import store from 'client/store';
import { Tab, Tabs } from '@smartplatform/ui';
import { getModelWhere, relative } from 'client/helpers';
import { Picture } from 'components';
import { PICTURE_FULL_PROPS } from 'constants';
import ExternalLink from 'helpers/externalLink';

@observer
class SuperBlock extends Component {
	@observable record = null;
	@observable recordTabs = null;
	@observable units = null;
	@observable unitsTabs = null;
	@observable tab = null;
	@observable isLoading = true;
	@observable selected = 0;

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		const isMainShowAll = false;
		const where = getModelWhere(store.model.SuperBlockTabTitle, isMainShowAll);
		this.record = await store.model.SuperBlockTabTitle.find({ where });
		if (this.record.totalCount > 0) {
			this.id = this.record[0] && this.record[0].id;
			this.recordTabs = await store.model.SuperBlockTabTitle.findById(this.id && this.id);
			this.tab = this.record[0] && this.record[0].id;
			this.units = await store.model.SuperBlockUnit.find({ where: { tabTitleId: this.id }, include: { relation: 'mainIcons' } });
			this.isLoading = false;
		}
	};

	handleClick = (e) => {
		let value = e.target.getAttribute('data-value');
		if (value !== null) {
			this.selected = value;
		}
	};

	setTab = async (tab) => {
		this.tab = tab;
		this.recordTabs = await store.model.SuperBlockTabTitle.findById(this.tab);
		this.unitsTabs = await store.model.SuperBlockUnit.find({ where: { tabTitleId: this.tab }, include: { relation: 'mainIcons' } });
	};

	render() {
		if (this.isLoading) return null;
		const totalCount = this.unitsTabs ? this.unitsTabs?.totalCount : this.units?.totalCount;
		const element = document.querySelector('.tab-blocks-container');
		const widthBlocksContainer = element?.offsetWidth;

		const width = widthBlocksContainer === 1200 ? 1176 : 1416;

		const style = this.selected >= 1 ? { transform: `translateX(-${this.selected * width}px)` } : {};
		const renderIndicator = [];
		for (let i = 0; i < totalCount / 4; i++) {
			renderIndicator.push(<li data-value={i} key={i} className={this.selected == i ? 'active' : ''}></li>);
		}

		return (
			<>
				{this.record && (
					<div className='super-block-container'>
						{this.recordTabs?.image && (
							<Picture src={this.recordTabs?.downloadFile('image')} className='background-image' {...PICTURE_FULL_PROPS} alt={this.recordTabs.image} />
						)}
						<div className='tab-blocks-container'>
							<Tabs type='manual'>
								{this.record.map((rec) => (
									<Tab title={rec?.title} onClick={() => this.setTab(rec?.id)} key={rec?.id} />
								))}
							</Tabs>
							{this.tab && (
								<div className='tab-blocks'>
									{!this.isLoading && this.unitsTabs
										? this.unitsTabs.map((unit) => (
												<ExternalLink to={unit?.blockLink} className='block' style={style} key={unit.id} alt={unit.title}>
													<div className='block-title'>{unit.title}</div>
													{unit?.mainIcons?.name === 'Файл' && <div className='block-info'>{t('downloadFile')}</div>}
													<div className='block-createdAt-icon'>
														<div className='block-createdAt'>{relative(unit?.date0)}</div>
														<div className='block-icon'>
															{unit?.mainIcons && <img src={unit?.mainIcons.downloadFile('icon')} alt={unit?.mainIcons.icon} className='image' />}
														</div>
													</div>
												</ExternalLink>
											))
										: this.units &&
											this.units.map((unit) => (
												<ExternalLink to={unit?.blockLink} className='block' style={style} key={unit.id} alt={unit.title}>
													<div className='block-title'>{unit.title}</div>
													{unit?.mainIcons?.name === 'Файл' && <div className='block-info'>{t('downloadFile')}</div>}
													<div className='block-createdAt-icon'>
														<div className='block-createdAt'>{relative(unit?.createdAt)}</div>
														<div className='block-icon'>
															{unit?.mainIcons && <img src={unit?.mainIcons.downloadFile('icon')} alt={unit?.mainIcons.icon} className='image' />}
														</div>
													</div>
												</ExternalLink>
											))}
								</div>
							)}

							{totalCount > 4 && (
								<ul onClick={this.handleClick} className='super-block-indicators'>
									{renderIndicator}
								</ul>
							)}
						</div>
					</div>
				)}
			</>
		);
	}
}

export default SuperBlock;
