import React from 'react';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import MediaQuery from 'react-responsive';

import { SmallArticle } from 'components';
import store from 'client/store';
import { Loader } from '@smartplatform/ui';
import { t } from 'i18next';

@inject('store')
@observer
export default class News extends React.Component {
	constructor(props) {
		super(props);
		this.store = props.store;
	}

	render() {
		const { recordsSmall, isSmallLoading } = this.store;

		if (isSmallLoading) return <Loader size={20} />;

		return (
			<div className='news-more'>
				<div className='articles-grid'>
					{recordsSmall.length
						? recordsSmall.map((article, i) => {
								if (i === 0) {
									return (
										<React.Fragment key={article.id}>
											<MediaQuery minWidth={768}>
												<SmallArticle article={article} />
											</MediaQuery>
										</React.Fragment>
									);
								}
								return <SmallArticle key={article.id} article={article} />;
							})
						: t('article.notFound')}
				</div>
			</div>
		);
	}
}
