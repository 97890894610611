import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ImageNull from 'client/img/data-open/icon-folder-csv.png?url';
import { formatDate } from 'client/helpers';
import t from 'i18n';

export default class OpenData extends Component {
	constructor(props) {
		super(props);
	}
	render() {
		const { data, isLoading } = this.props;
		if (isLoading) return null;
		const stringWithoutTags = data.text?.replace(/(<([^>]+)>)/gi, '');
		return (
			<>
				<Link to={`/open-data/${data.id}`} className='open-data-container' alt={t('openData.title')}>
					{data.image ? (
						<img className='images' src={vacancy.downloadFile('image') + '?image=[{"resize":{"width":64}}]'} alt={vacancy.image} />
					) : (
						<div className='images'>
							<img src={ImageNull} alt={t('alt.emptyImage')} />
						</div>
					)}
					<div className='mid-data-items'>
						{data && <div className='theme p6'>{data.dataCategory.name}</div>}
						{data.name && <div className='text h7'>{data.name}</div>}
						<div className='initiator p3'>{data.agency?.name}</div>
						<div className='info'>
							<div className='date p3'>Дата актуальности: {formatDate(data.actualDataAt, 'dd.MM.yyyy')}</div>
							<div className='date p3'>Обновлено: {formatDate(data.updatedAt, 'dd.MM.yyyy')}</div>
							<div className='date p3'>Опубликовано: {formatDate(data.publishAt, 'dd.MM.yyyy')}</div>
						</div>
					</div>
				</Link>
			</>
		);
	}
}
