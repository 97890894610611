import React from 'react';
import { observable } from 'mobx';
import { Provider, observer } from 'mobx-react';
import './style.scss';
import store from 'client/store';
import { Tab, Tabs } from '@smartplatform/ui';
import { SecondaryLayout, Picture, SectionCard, AutoAnimate, Desktop, Mobile, NotPhone, Phone } from 'components';
import t from 'i18n';
import { getModelWhere } from 'helpers';
import ExternalLink from 'helpers/externalLink';
import EmptyImage from '../../../img/empty-link.svg';

@observer
export default class LinksFeed extends React.Component {
	@observable isLoading = true;
	@observable categories = true;
	@observable tab = 0;
	@observable selected = 0;

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		const isMainShowAll = false;
		const where = getModelWhere(store.model.LinksFeedLink, isMainShowAll);
		this.records = await store.model.LinksFeedLink.find({ where });
		const categories = await store.model.LinksFeedCategory.find({
			fields: ['id', 'name'],
			include: [{ relation: 'links', scope: { fields: ['id', 'siteId', 'organizationId', 'languageId', 'publish', 'categoryId'], limit: 1, where } }],
		});
		this.categories = categories.filter((r) => !!r.links().length);
		this.tab = this.categories?.[0]?.id;
		this.isLoading = false;
	};

	handleClick = (e) => {
		let value = e.target.getAttribute('data-value');
		if (value !== null) {
			this.selected = value;
		}
	};

	setTab = (tab) => {
		this.tab = tab;
	};

	render() {
		if (this.isLoading) return null;

		const style = this.selected >= 1 ? { transform: `translateX(-${this.selected * 1200}px)` } : {};

		const slides = [[]];

		const categoryLinks = this.records.filter((element) => element.categoryId == this.tab);

		const main = categoryLinks.find((element) => element.main);

		let maxSlides = main ? 6 : 8;

		categoryLinks.forEach((element) => {
			if (element === main) return;

			if (slides[slides.length - 1].length >= maxSlides) {
				slides.push([]);
				maxSlides = 8;
			}
			slides[slides.length - 1].push(element);
		});

		const renderIndicator = [];
		for (let i = 0; i < slides.length; i++) {
			renderIndicator.push(<li data-value={i} key={i} className={this.selected == i ? 'active' : ''}></li>);
		}

		const contentSliderItems = (
			<>
				{slides.map((links, index) => (
					<LinkGrid key={index} main={index === 0 && main} links={links} />
				))}
			</>
		);

		return (
			<Provider store={this.store}>
				<SecondaryLayout>
					<SectionCard title={t('usefulLinks.title')} className='useful-links'>
						<Tabs type='manual'>
							{this.categories.map((category, i) => (
								<Tab key={i} title={category.name} onClick={() => this.setTab(category.id)} />
							))}
						</Tabs>
						<div className='slider'>
							<NotPhone>
								<div className='slide-items' style={style}>
									{contentSliderItems}
								</div>
							</NotPhone>
							<Phone>
								<div className='slide-items'>{contentSliderItems}</div>
							</Phone>
							{renderIndicator.length > 1 && (
								<ul onClick={this.handleClick} className='indicators'>
									{renderIndicator}
								</ul>
							)}
						</div>
					</SectionCard>
				</SecondaryLayout>
			</Provider>
		);
	}
}

function LinkGrid({ main, links }) {
	const twoRows = (main && links.length > 3) || links.length > 4;
	return (
		<AutoAnimate className={`slide-item ${main ? 'has-main-link' : ''}`}>
			{main && (
				<div className='main-link'>
					<Link link={main} alt={main.title} />
				</div>
			)}
			<div className={`links-grid  ${main ? 'cols-3' : ''} ${twoRows ? 'two-rows' : ''}`}>
				{links?.map((link, i) => (
					<Link key={i} link={link} alt={link.title} />
				))}
			</div>
		</AutoAnimate>
	);
}

@observer
class Link extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<div className='link'>
				<ExternalLink className='content' to={this.props.link?.link} alt={this.props.link?.title}>
					<p>{this.props.link?.title}</p>
					<div className='content-img-wrapper'>
						{this.props.link?.image ? <Picture src={this.props.link?.downloadFile('image')} width={160} alt={this.props.link?.title} /> : <EmptyImage />}
					</div>
				</ExternalLink>
			</div>
		);
	}
}
