import React, { Component } from 'react';
import store from 'client/store';
import { observer } from 'mobx-react';
import { Tabs, Tab } from '@smartplatform/ui';
import QuestionAnswerStore from './store';
import { QuestionAnswerCard, SectionCard, AutoAnimate, Button } from 'components';
import t from 'i18n';
import './style.scss';
import Poll from 'client/pages/discussions/list/Poll';
import pollImg from 'img/questions-answers/image-main-widget.png';

@observer
export default class QuestionAnswer extends Component {
	constructor(props) {
		super(props);
		this.store = new QuestionAnswerStore();
	}

	render() {
		const { onCategoryChange, records, categories, poll } = this.store;

		if (!categories.length) return null;

		return (
			<div className='question-answer-container'>
				<SectionCard title={t('questionAnswer.title')} icon='question' className='question-answer'>
					<Tabs type='manual' className='tabs'>
						{[{ name: t('all') }, ...categories].map((category) => {
							return <Tab title={category.name} key={category.name} onClick={() => onCategoryChange(category)} />;
						})}
					</Tabs>

					<AutoAnimate className='list'>
						{records.map((record) => (
							<QuestionAnswerCard key={record.id} record={record} />
						))}
					</AutoAnimate>
				</SectionCard>

				<SectionCard title={t('discussions.title')} icon='poll' className='question-answer-poll'>
					<img src={pollImg} alt={t('discussions.title')} />
					{poll && <Poll poll={poll} />}
					<Button onClick={() => store.route.push({ path: '/discussions' })} color='grey'>
						{t('discussions.goToOther')}
					</Button>
					<div className='question-answer-appeal'>
						<div className='h5'>{t('citizensAppeals.title')}</div>
						<div className='p2'>{t('citizensAppeals.fillFormCitizensElectronicAppeal')}</div>
						<Button onClick={() => store.route.push({ path: '/citizens-appeals' })} color='white'>
							{t('appeals.submitAppeal')}
						</Button>
					</div>
				</SectionCard>
			</div>
		);
	}
}
