import React from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import './style.scss';
import ExternalLink from 'helpers/externalLink';
import { t } from 'i18next';

@observer
export default class SlidePlacement extends React.Component {
	render() {
		const { record } = this.props;
		if (!record || (!record.image && !record.html)) return null;

		const img = (
			<img
				src={record && record.downloadFile('image' + `?image=[{"resize":{"width":447, "height":280}}]`)}
				style={{ width: 447, height: 280 }}
				alt={record.image}
			/>
		);
		const link = record && record.link;
		const html = record && record.html;
		const className = classNames(`sliders`, `place-0`, {
			linked: !!link,
		});
		return (
			<>
				<div className='top-slide-container'>
					<div className={className}>
						<div className='slide-text-block'>
							{record.header && <div className='slide-header h4'>{record.header}</div>}
							{record.description && <div className='slide-description p1'>{record.description}</div>}
							<div className='slide-text-block-button-container'>
								{link ? (
									<ExternalLink to={link} alt={t('goTo')}>
										<button className='slide-text-block-button p1'>{t('goTo')}</button>
									</ExternalLink>
								) : (
									<button className='slide-text-block-button p1'>{t('goTo')}</button>
								)}
							</div>
						</div>
						{html ? (
							<div dangerouslySetInnerHTML={{ __html: html }} />
						) : link ? (
							/^http/.test(link) ? (
								<a target='_blank' href={link} alt={record.image}>
									{img}
								</a>
							) : (
								<Link to={link} alt={record.image}>
									{img}
								</Link>
							)
						) : (
							img
						)}
					</div>
				</div>
			</>
		);
	}
}
