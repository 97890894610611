import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { Button, WidgetVariant1, WidgetVariant2, WidgetVariant3, WidgetVariant4, WidgetVariant5, QuickAccessBar, SubmitSearchInput, Picture } from 'components';
import { PICTURE_FULL_PROPS } from 'constants';
import { DesktopMenu } from './menu';
import LogoRoot from './logo/LogoRoot';
import { Language } from './Language';
import { BadVision } from './BadVision';
import store from 'client/store';
import 'client/img/pattern-stars-right.svg?url';
import 'client/img/pattern-stars-left.svg?url';
import 'client/img/new-logo-256.png';
import './mainPageHeader.scss';
import BadVisionPopup from './bad-vision/BadVisionPopup';
import { TopicButton } from 'components/topic-button';
import { t } from 'i18next';

@observer
export class MainPageHeader extends React.Component {
	@observable widgetsIsEmpty = false;

	constructor(props) {
		super(props);
		this.init();
	}

	onSubmitSearch = async (value) => {
		store.route.push({ path: '/search', params: { search: value } });
	};

	init = () => {
		const divElement = document.querySelector('.page-header-title-right');

		if (!divElement || divElement.childElementCount === 0) {
			this.widgetsIsEmpty = true;
		} else {
			this.widgetsIsEmpty = false;
		}
	};

	render() {
		return (
			<>
				{store.local.badVision && <BadVisionPopup />}
				<div className='main-header'>
					{store.ui.site.image ? (
						<Picture
							src={store.ui.site?.downloadFile('image')}
							className='cover-header-image'
							{...PICTURE_FULL_PROPS}
							pictureClassName='cover-header-image-wrapper'
							alt={t('imageOnMain')}
						/>
					) : (
						<div className='cover-header-noimage'></div>
					)}
					<div className='wrapper'>
						<div className='content'>
							<div className='header'>
								<div className='header-content'>
									<div className='header-left-right'>
										<div className='header-left'>
											<LogoRoot />
										</div>
										<div className='header-right'>
											<Language />
											<BadVision />
										</div>
									</div>
									<div>
										<DesktopMenu />
									</div>
									<div className='page-header-title-container'>
										<div className='page-header-title-left'>
											<div className='page-title'>
												{store.ui.site?.isMain ? (
													<>
														<span className='page-title-text-1'>Официальный информационный портал</span>
														<span className='page-title-text-2'>Республики Саха (Якутия)</span>
													</>
												) : (
													<span className='page-title-text-1'>{store.ui.site?.name}</span>
												)}
											</div>
											{!!this.props.topicButtons.length && (
												<div className='page-header-buttons'>
													{this.props.topicButtons.map((button, index) => (
														<TopicButton key={index} record={button} />
													))}
												</div>
											)}
											<div className='page-header-search'>
												<SubmitSearchInput onSubmit={this.onSubmitSearch} />
											</div>
										</div>
										<div className='page-header-title-right'>
											<WidgetVariant1 />
											<WidgetVariant2 />
											<WidgetVariant3 />
											<WidgetVariant4 />
											<WidgetVariant5 />
											<QuickAccessBar />
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}
