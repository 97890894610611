import React from 'react';
import { getCookie, setCookie } from 'helpers/Cookie';
import { t } from 'i18next';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import './style.scss';

@observer
export class CookiePopUp extends React.Component {
	@observable hide = '';

	constructor(props) {
		super(props);
		this.hide = getCookie('accept-cookie');
	}

	onAccept = () => {
		setCookie('accept-cookie', true);
		this.hide = true;
	};

	render() {
		if (this.hide) return null;

		return (
			<div className='wrapper-cookie-message-popup'>
				<div className='left'>
					<p className='additional-text'>{t('cookie.additionalTextFirst')}</p>
					<p className='additional-text'>{t('cookie.secondTextSecond')}</p>
				</div>
				<div className='right'>
					<p className='button-accept' onClick={this.onAccept}>
						{t('cookie.buttonText')}
					</p>
				</div>
			</div>
		);
	}
}
