import React from 'react';
import { observable } from 'mobx';
import appStore from 'client/store';
import { observer } from 'mobx-react';
import { Picture } from 'components';
import NoImage from 'img/events-no-image.svg';
import './style.scss';
import { t } from 'i18next';
import { Link } from 'react-router-dom';

@observer
export class SupportMeasureTopics extends React.Component {
	@observable records = [];
	constructor(props) {
		super(props);
		this.fetchRecords();
	}

	fetchRecords = async () => {
		if (appStore.ui.site.organizationId) {
			this.records = await appStore.model.ViewTopicCard.find({
				where: { organizationIds: { ilike: `%[${appStore.ui.site.organizationId}]%` }, isActive: true },
				include: [{ relation: 'topic', scope: { fields: ['id', 'code', 'name'] } }],
				order: 'priority asc nulls last',
			});
		}
	};

	render() {
		if (!this.records.length) return null;
		return (
			<div className='wrapper-support-measure-topics'>
				<div className='head'>
					<p>{t('supportMeasure.title')}</p>
				</div>
				<div className='items'>
					{this.records.map((record) => {
						if (record.topic && record.topic.code) {
							return (
								<Link to={`/support-measure/topic/${record.topic.code}`} className='topic-card-item' alt={record.topic.name}>
									<div className='top'>
										{record.image ? (
											<img
												src={new appStore.model.TopicCard(record).downloadFile('image') + `?image=[{"resize":{"width":282}}]&v=${Date.now()}`}
												alt={record.topic.name}
											/>
										) : (
											<NoImage />
										)}
										<div className='topic-btn'>
											<p className='topic-name' title={record.topic.name}>
												{record.topic.name}
											</p>
										</div>
									</div>
								</Link>
							);
						}
					})}
				</div>
				<Link to={'/support-measure'} className='sp-btn sp-btn-info button-show-more sp-btn-md' alt={t('supportMeasure.goToOtherMeasures')}>
					{t('supportMeasure.goToOtherMeasures')}
				</Link>
			</div>
		);
	}
}
