import qs from 'qs';
import { createBrowserHistory, createMemoryHistory } from 'history';
import { observable } from 'mobx';
import { Rest, ModelStore } from '@smartplatform/model-store';
import { RouteStore } from '@smartplatform/route-store';
import { LocalStore } from '@smartplatform/local-store';
import UIStore from './uiStore';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import punycode from 'punycode';
import { changeLanguage } from 'i18next';
import { parseConfig } from '../tools/parseConfig';
import { mergeConfigs } from '../tools/mergeConfig';

export const isBrowser = typeof window !== 'undefined';

/**
 * Глобальный контейнер сторов
 */
export class AppStore {
	@observable isInitialized = false;

	local; // Локальное хранилище
	model; // Хранилище моделей
	route; // Хранилище маршрута браузера
	api; // API обмена с сервером
	admin; // Хранилище @admin
	ui; // ui
	cache;
	@observable appKey = 0;

	constructor() {
		this.history = isBrowser ? createBrowserHistory() : createMemoryHistory();
		this.ui = new UIStore(this);
		this.cache = {};

		this.local = new LocalStore();
		this.local.extend({
			countyId: null,
			dynamicLayers: [],
			languageId: null,
			badVision: false,
			weather: {
				show: false,
				minimized: false,
				layer: 'pm25',
			},
			wind: {
				show: false,
				minimized: false,
				fadeOpacity: 0.995,
				speedFactor: 5,
				particleLife: 128,
				opacity: 0.8,
				webgl: true,
			},
			counties: {
				show: false,
			},
			filtersMaximized: false,
			extraLayers: {},
		});
		this.local.save();

		this.rest = new Rest();
		this.model = new ModelStore({
			transport: this.rest,
			cache: false,
			autoConnect: false,
		});

		this.route = new RouteStore({
			history: this.history,
			decode: qs.parse,
			encode: qs.stringify,
		});
	}

	init = async () => {
		this.domain = punycode.toUnicode(location.hostname);
		await this.initFingerprint();
		await this.model.connect();
		if (!this.model.error) {
			this.serverConfig = this.model.config || {};
			await this.fetchDbServerConfig();
		}
		await this.ui.init();
		this.patchDownloadFile('ViewAlbum', 'Album');
		this.patchDownloadFile('ViewMedia', 'Media');
		this.patchDownloadFile('ViewArticle', 'Article');
		this.patchDownloadFile('ViewQuestionAnswer', 'QuestionAnswer');
		this.patchDownloadFile('ViewOpenData', 'OpenData');
		this.model.View.createView({ visitorId: this.visitorId, siteId: this.ui.site.id });
		const language = this.ui.languages.find((r) => r.id === this.local.languageId);
		changeLanguage(language?.code?.toLowerCase() || 'ru');
		this.isInitialized = true;
	};

	fetchDbServerConfig = async () => {
		const configRecords = await this.model.Config.find();
		this.dbServerConfig = parseConfig(configRecords);
		this.mergedConfig = mergeConfigs({ serverConfig: this.serverConfig, dbConfig: this.dbServerConfig });
	};

	initFingerprint = async () => {
		const fp = await FingerprintJS.load();
		const result = await fp.get();
		this.visitorId = result.visitorId;
		this.setVisitorCookie();
	};

	patchDownloadFile = (viewName, modelName, id = 'id') => {
		const modelStore = this.model;
		if (modelStore[viewName] && modelStore[modelName]) {
			modelStore[viewName].prototype.downloadFile = function (property) {
				return modelStore.downloadFile(modelStore[modelName], this[id], property);
			};
		}
	};

	setVisitorCookie = () => {
		document.cookie = `_vid=${this.visitorId};path=/;max-age=31536000`;
	};

	reloadApp = () => this.appKey++;
}

const appStore = new AppStore();
if (isBrowser) window.APP_STORE = appStore;

export default appStore;
