import React from 'react';
import { observer } from 'mobx-react';
import './style.scss';
import { Link } from 'react-router-dom';
import store from 'client/store';
import DropdownMenu from './DropdownMenu';
import t from 'i18n';
import IMAGES_MAP from 'constants/MenuPortalFooterImages';

@observer
export class Menu extends React.Component {
	generateLinkPath = (record) => {
		const { module, link, subCategoryCode, subjectCode } = record;
		let _link = link;
		if (module) {
			_link = `/${module}`;
		}
		if (module === 'documents' && subjectCode) {
			_link = `/${module}/subject/${subjectCode}`;
		}
		if (module === 'question-answer' && subCategoryCode) {
			_link = `/${module}/subCategory/${subCategoryCode}`;
		}
		return _link;
	};

	generateName = (record) => {
		const { module, name, subjectName, subCategoryName } = record;
		let title = name;
		if (module) {
			title = t(`sites.${module}`);
			if (subjectName && module === 'documents') title = subjectName;
			if (subCategoryName && module === 'question-answer') title = subCategoryName;
		}
		return title;
	};

	getMenuElement = (record) => {
		return (
			<Link to={{ pathname: this.generateLinkPath(record) }} target={!record.module ? '_blank' : '_self'} alt={this.generateName(record)}>
				<div className='link-content'>
					{record.module && IMAGES_MAP[record.module]}
					{this.generateName(record)}
				</div>
			</Link>
		);
	};

	render() {
		const isActive = (path) => (path === store.route.path ? ' active' : '');

		const items = [];
		store.ui.menuMain.forEach((menu) => {
			const { module, children } = menu;
			const activePath = this.generateLinkPath(menu);
			items.push(
				<div className={this.props.className + (module ? isActive(`/${activePath}`) : '')} key={items.length}>
					{this.getMenuElement(menu)}
					{!!children.length && <DropdownMenu items={children.map((x) => this.getMenuElement(x))} />}
				</div>
			);
		});
		return <div className='links'>{items}</div>;
	}
}
