import React, { Component } from 'react';
import './style.scss';
import { relative } from 'helpers';
import { Link } from 'react-router-dom';

export default class SupportMeasureSmall extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		const { records } = this.props;

		return (
			<Link to={`/support-measure/${records.id}`} alt={records?.title}>
				<div className='support-measure-small'>
					<div className='topic'>{records.topics?.name}</div>
					<div className='title'>{records?.title}</div>
					<div className='data'>{relative(records?.date0)}</div>
				</div>
			</Link>
		);
	}
}
