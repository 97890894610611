import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import SmallQuestionAnswer from './question-answer-small/SmallQuestionAnswer';
import './style.scss';
import ArrowLeft from '-!svg-react-loader?name=Icon!client/img/icon-arrow_left-black.svg';
import ArrowRight from '-!svg-react-loader?name=Icon!client/img/icon-arrow_right.svg';
import AllSitesIcon from '-!svg-react-loader?name=Icon!client/img/icon-all_sites-grey.svg';
import store from 'client/store';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { formatDate } from 'client/helpers';
import { Desktop, Mobile, NotPhone, Phone, SocialSinglePage } from 'components';
import { SvgIcon } from 'components/svgIcon';
import { t } from 'i18next';
import IconIpload from 'img/icon-upload.svg';
import { MetaInfo } from 'components/meta-info';

@withRouter
@observer
export default class QuestionAnswerPage extends Component {
	@observable record = null;
	@observable smallQuestionAnswerItems = null;
	@observable error = null;
	@observable isLoading = true;
	constructor(props) {
		super(props);
		this.init();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.match.params.id !== prevProps.match.params.id || this.props.id !== prevProps.id) {
			this.init();
		}
	}

	init = async () => {
		this.id = this.props.match.params.id;
		if (this.id) {
			try {
				this.record = await store.model.QuestionAnswer.findById(this.id, {
					include: ['branchCategory', 'subCategory', 'labels', 'attachments'],
				});
				this.smallQuestionAnswerItems = await store.model.QuestionAnswer.find();
				this.prevQuestionAnswer = await store.model.QuestionAnswer.find({
					where: { id: { neq: this.id, publishAt: { lte: this.record.publishAt } } },
					limit: 1,
					order: 'publishAt desc , id desc',
				});
			} catch (e) {
				this.error = e.message;
			}
		} else {
			this.error = 'Страница не найдена';
		}
		this.isLoading = false;
	};

	render() {
		if (this.isLoading) return <div className='question-answer-page-container-isLoading'>...</div>;
		const tags = this.record.labels();
		const tagName = tags.map((t) => <span>{' #' + t.name}</span>);
		return (
			<div className='page-content-single question-answer-page-container'>
				<div className='question-answer-page-item'>
					<NotPhone>
						<div className='head'>
							<div className='category-subcategory'>
								<Link to='/question-answer' alt={t('questionAnswer.title')}>
									<div className='category date p2'>{t('questionAnswer.title')}</div>
								</Link>
								<div className='subcategory date p2'>{this.record.title}</div>
							</div>
							<div className='to-portal'>
								<AllSitesIcon />
								<Link to={'/'} alt={t('goPortal')}>
									<div className='go-portal p4'>{t('goPortal')}</div>
								</Link>
							</div>
						</div>
					</NotPhone>
					<div className='question-answer-item'>
						<Phone>
							<Link to='/question-answer' className='wrapper-back-link' alt={t('searchPage.questionAnswer')}>
								<SvgIcon name='left-arrow' />
								{t('searchPage.questionAnswer')}
							</Link>
						</Phone>
						<div className='title h1'>{this.record?.title}</div>
						<div className='wrapper-date-categories'>
							<div className='date-category-subcategory p6'>
								{this.record.branchCategory && <div className='category'>{this.record.branchCategory?.name}</div>}
								<div className='subcategory'>{this.record.subCategory?.name}</div>
							</div>
						</div>
						<div className='description p1' dangerouslySetInnerHTML={{ __html: this.record.description }} />
						<div className='applications'>
							{this.record.attachments().map((a) => (
								<a className='application' target='_blank' href={a.downloadFile('filename')} download alt={a.filename}>
									<div>{a.filename}</div>
									<IconIpload />
								</a>
							))}
						</div>
						<div className='tags-social p1'>
							<div>{tagName}</div>
							<div className='social-share'>
								<div className='share'>{t('share')}</div>
								<div className='social-bottom'>
									<SocialSinglePage title={this.record?.title} />
								</div>
							</div>
						</div>
						<MetaInfo record={this.record} />
						<div className='back-forward'>
							<Link to='/question-answer' alt={t('backToSection')}>
								<div className='back'>
									<ArrowLeft className='arrow-left' />
									<span className='p4'>{t('backToSection')}</span>
								</div>
							</Link>
							<Link to={`/question-answer/${this.prevQuestionAnswer[0].id}`} alt={this.prevQuestionAnswer[0].title}>
								<div className='forward'>
									<ArrowRight className='arrow-right' />
									<span className='p4'>{this.prevQuestionAnswer[0].title}</span>
								</div>
							</Link>
						</div>
					</div>
				</div>
				{/* SideBar */}
				<div className='sidebar'>
					<div className='sidebar-news'>
						<span className='p4'>{t('interesting')}</span>
						<span className='line'></span>
					</div>
					<div className='list'>
						{this.smallQuestionAnswerItems &&
							this.smallQuestionAnswerItems.map((items) => {
								return <SmallQuestionAnswer key={items.id} questionAnswer={items} />;
							})}
					</div>
				</div>
			</div>
		);
	}
}
