import store from 'client/store';
import {
	Footer,
	MainPageHeader,
	SliderBig,
	SliderSmall,
	PopularServicesBig,
	SupportMeasureWidget,
	SuperBlock,
	MainPageHeaderMobile,
	NotPhone,
	Phone,
} from 'components';
import { observer } from 'mobx-react';
import React from 'react';
import News from './news/News';
import './style.scss';
import SummaryBlock from 'components/summary-block/SummaryBlock';
import LinksFeed from './links-feed/LinksFeed';
import InteractiveMap from './Interactive-map/InteractiveMap';
import MediaFeed from './media-feed/MediaFeed';
import MediaBroadcast from './media-broadcast/MediaBroadcast';
import AllSites from './all-sites';
import Events from './events/EventsSmall';
import QuestionAnswer from './question-answer';
import Poster from './poster/Poster';
import PosBanner from './pos-banner';
import { observable } from 'mobx';
import { SupportMeasureTopics } from './support-measure-topics';
import { WidgetsPOS } from './WidgetsPOS';

@observer
export default class MainPage extends React.Component {
	@observable topicButtons = [];

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		const [buttonGroup] = await store.model.TopicButtonGroup.find({
			where: { siteTemplateId: store.ui.site.templateId, code: 'HOME-BANNER' },
			include: [{ relation: 'topicButtons', scope: { where: { isActive: true }, order: 'priority asc nulls last' } }],
			fields: ['id', 'maxCount', 'code', 'siteTemplateId'],
		});
		this.topicButtons = buttonGroup.topicButtons().slice(0, buttonGroup.maxCount);
	};

	render() {
		const modules = {
			'popular-banner': SummaryBlock,
			popular: PopularServicesBig,
			superblock: SuperBlock,
			map: InteractiveMap,
			news: News,
			'question-answer': QuestionAnswer,
			'all-sites': AllSites,
			'support-measures': SupportMeasureWidget,
			resources: LinksFeed,
			events: Events,
			'banner-big': SliderBig,
			'banner-feed': SliderSmall,
			'media-feed': MediaFeed,
			'media-broadcast': MediaBroadcast,
			'pos-banner': PosBanner,
			poster: Poster,
			'support-measure-topic': SupportMeasureTopics,
			'widgets-pos': WidgetsPOS,
		};

		let items = [];
		for (const module of store.ui.modules.filter((r) => r.code)) {
			const Component = modules[module.code];
			if (typeof Component === 'function') {
				items.push(<Component key={module.code} />);
			}
		}
		return (
			<div className='main-page' key='root'>
				<NotPhone>
					<MainPageHeader topicButtons={this.topicButtons} />
				</NotPhone>
				<Phone>
					<MainPageHeaderMobile topicButtons={this.topicButtons} />
				</Phone>
				<div className='main-page-content'>{items}</div>
				<Footer />
			</div>
		);
	}
}
