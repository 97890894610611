import React from 'react';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import './style.scss';
import { formatTime } from 'client/helpers';
import DownloadIcon from 'client/img/data-open/icon-download.svg';
import Slider from 'react-slick';
import { Mobile, Phone, Picture, ViewsCountActive } from 'components';
import 'react-slick/dist/react-slick.min';
import { VideoPlayer } from 'components';
import { isIframeOrUrl } from './helper';
import ArrowLeft from 'img/arrow-left_1.svg';
import ArrowRight from 'img/arrow-right_1.svg';
import { SvgIcon } from 'components/svgIcon';
import { t } from 'i18next';

@withRouter
@observer
export default class Single extends React.Component {
	getContent = () => {
		const { record } = this.props;
		const media = record.media()[0];

		switch (record.type) {
			case 'video': {
				if (!media) return;
				const url = isIframeOrUrl(media['videoLink']) ? media['videoLink'] : media.downloadFile('videoLink');
				return <VideoPlayer width={870} height={490} autoPlay={false} show={!!url} key={url} url={url} />;
			}
			case 'audio': {
				if (!media) return;
				return (
					<audio src={media.downloadFile('audioFile')} controls>
						Ваш браузер не поддерживает <code>audio</code>.
					</audio>
				);
			}
			case 'photo': {
				if (record.media().length) {
					const settings = {
						dots: true,
						infinite: true,
						speed: 500,
						slidesToShow: 1,
						slidesToScroll: 1,
						arrows: true,
						fade: true,
						adaptiveHeight: true,
						nextArrow: (
							<div className='castom-arrow-right'>
								<ArrowRight />
							</div>
						),
						prevArrow: (
							<div className='castom-arrow-left'>
								<ArrowLeft />
							</div>
						),
					};
					return (
						<Slider {...settings} className='media-slider-main'>
							{record.media().map((x) => (
								<div key={x.id} className='media-slider-content'>
									<Picture src={x.downloadFile('image')} width={870} quality={100} alt={record.name} />
								</div>
							))}
						</Slider>
					);
				}
			}
			default: {
				return null;
			}
		}
	};

	render() {
		const { record, onClose } = this.props;
		const { type, name, author, contentTypeName, contentSourceName, viewsCount, date, description } = record;
		const media = record.media()[0];
		const labels = record.labels();
		const isVideoDownload = media && !isIframeOrUrl(media['videoLink']);

		return (
			<div className='media-single'>
				<Phone>
					<div className='wrapper-back'>
						<div className='wrapper-control-back' onClick={onClose}>
							<SvgIcon name='left-arrow' />
							<span>{t('back')}</span>
						</div>
					</div>
				</Phone>
				<div className='single-content'>{this.getContent()}</div>
				<div className='single-bar'>
					{labels.length !== 0 && (
						<div>
							<span className='single-labels'>
								{labels.map((x) => (
									<span>{x.name}</span>
								))}
							</span>
						</div>
					)}
					{media && isVideoDownload && type === 'video' && (
						<a href={media.downloadFile('videoLink')} download className='single-download' alt={t('download')}>
							<DownloadIcon />
							{t('download')}
						</a>
					)}
				</div>
				<div className='single-name'>{name}</div>
				<div className='single-author'>
					Автор: <span className='single-text-value'>{author}</span>
				</div>
				<div className='single-source'>
					Первоисточник: <span className='single-text-value'>{contentSourceName}</span>
				</div>
				<div className='single-view-date'>
					{viewsCount} просмотров
					{date ? ` | ${formatTime(date)}` : ''}
				</div>
				<div className='single-description' dangerouslySetInnerHTML={{ __html: description }}></div>
				<ViewsCountActive record={record} />
			</div>
		);
	}
}
