import React from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import './style.scss';
import { Tooltip, Picture } from 'components';

@observer
export default class SlideBig extends React.Component {
	render() {
		const { record } = this.props;
		if (!record || (!record.image && !record.html)) return null;

		const img = <Picture src={record.downloadFile('image')} className='slide-picture' alt={record.image} />;
		const link = record && record.link;
		const html = record && record.html;
		const className = classNames(`sliders-big`, `place-0`, {
			linked: !!link,
		});
		return (
			<>
				{record && (
					<div className='big-top-slide-container'>
						<div className={className}>
							<Tooltip position='bottom' content={record.description}>
								{html ? (
									<div dangerouslySetInnerHTML={{ __html: html }} />
								) : link ? (
									/^http/.test(link) ? (
										<a target='_blank' href={link} alt={record.image}>
											{img}
										</a>
									) : (
										<Link to={link} alt={record.image}>
											{img}
										</Link>
									)
								) : (
									img
								)}
							</Tooltip>
						</div>
					</div>
				)}
			</>
		);
	}
}
