import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import store from 'client/store';
import { cutEmptyParagraphs } from '../../../utils';
import Polls from './Polls';
import { Breadcrumbs, Mobile, Phone } from 'components';
import { formatDate } from 'client/helpers';
import Attention from 'img/attention.svg';
import { Button } from '@smartplatform/ui';
import User from 'img/user.svg';
import './style.scss';
import { Link } from 'react-router-dom';
import { SvgIcon } from 'components/svgIcon';
import { t } from 'i18next';

@observer
export default class Single extends React.Component {
	@observable record = null;
	@observable polls = null;
	@observable isVoted = false;
	@observable isLoading = true;

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		this.isLoading = true;
		this.id = this.props.match.params.id;

		const pollWithVotes = await store.model.Poll.find({
			where: { discussionId: this.id },
			include: {
				relation: 'votes',
				scope: {
					where: { visitorId: store?.visitorId },
				},
			},
		});

		for (const poll of pollWithVotes) {
			if (poll.votes().length > 0) {
				this.isVoted = true;
				break;
			}
		}

		this.record = await store.model.Discussion.findById(this.id, {
			include: [
				{
					relation: 'comments',
					scope: {
						where: { publish: true },
						order: 'id desc',
					},
				},
				{
					relation: 'polls',
					scope: {
						include: ['fields', 'votes'],
					},
				},
			],
		});
		this.polls = this.record.polls();

		this.isLoading = false;
	};

	render() {
		if (this.isLoading) return null;

		const renderPoll = () => {
			if (new Date(this.record.endDate) < new Date() && this.record.endDate) {
				return (
					<div className='discussion-polls discussion-polls-warning'>
						<div className='warning-text'>
							Срок завершения опроса истёк. Спасибо за проявленный интерес! Вы можете пройти другие опросы на странице “Обсуждения”
						</div>
						<Button className='warning-button' onClick={() => store.route.push({ path: '/discussions' })}>
							Вернуться к опросам
						</Button>
					</div>
				);
			}
			if (!store.model.user && this.record.registeredOnly) {
				return (
					<div className='discussion-polls discussion-polls-warning'>
						<div className='warning-text'>
							<Attention />
							Для того, чтобы принять участие, вы должны быть авторизованы
						</div>
						<Button className='warning-button' onClick={() => store.route.push({ path: '/admin' })}>
							<User />
							Войти
						</Button>
					</div>
				);
			}
			if (this.isVoted && !this.record.showResults && new Date(this.record.endDate) > new Date()) {
				return (
					<div className='discussion-polls discussion-polls-warning'>
						<div className='warning-text'>Вы уже голосовали</div>
						<Button className='warning-button' onClick={() => store.route.push({ path: '/discussions' })}>
							Вернуться к опросам
						</Button>
					</div>
				);
			}
			return <Polls polls={this.polls} isVoted={this.isVoted} init={this.init} />;
		};

		return (
			<div className='page-content-single container discussion'>
				<Phone>
					<Link to='/discussions' className='wrapper-back' alt={t('sites.discussions')}>
						<SvgIcon name='left-arrow' />
						Опросы
					</Link>
				</Phone>
				<Breadcrumbs
					crumbs={[
						{
							title: 'Обсуждения',
							path: '/discussions',
						},
						{
							title: this.record.name,
						},
					]}
				/>
				<div className='discussion-name'>{this.record.name}</div>
				<div className='discussion-end'>Дата окончания опроса {formatDate(this.record.endDate)}</div>
				{this.record.text && <div className='discussion-description' dangerouslySetInnerHTML={{ __html: cutEmptyParagraphs(this.record.text) }} />}

				{renderPoll()}

				{/*<Comments user={this.user} discussionId={this.record.id} isEnded={recordEndDate} />*/}
			</div>
		);
	}
}
