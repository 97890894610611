import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import './style.scss';
import AppealIcon from 'img/appeal.svg';
import LoginIcon from 'img/login.svg';
import SiteMapStore from './store';
import { t } from 'i18next';
import { Button } from 'components/button';
import { LogoRoot } from '../../logo';
import { Menu } from '../Menu';
import CloseLogo from 'img/cancel.svg';
import { Language } from 'components/header/Language';
import { BadVision } from 'components/header/BadVision';
import { SubmitSearchInput } from 'components';
import store from 'client/store';
import { Loader } from '@smartplatform/ui';
import IMAGES_MAP from 'constants/MenuPortalFooterImages';
import ListItem from 'components/header-footer-list/ListItem';

@observer
export default class PortalMap extends Component {
	constructor(props) {
		super(props);
		this.store = new SiteMapStore();
	}

	componentDidMount() {
		document.body.style.overflow = 'hidden';
	}

	componentWillUnmount() {
		document.body.style.overflow = 'unset';
	}

	onSubmitSearch = async (value) => {
		store.route.push({ path: '/search', params: { search: value } });
	};

	renderPortalColumn = (catColumn, images) => catColumn.map((item) => <ListItem item={item} images={images} portalIsShowAll={this.store.isShowAll} />);

	render() {
		const classNameMenu = this.props.className !== 'text-black' ? 'link' : 'link blue-border-background';

		const { flattenMenuData, portals } = this.store;

		const columns = [[], [], [], []];
		flattenMenuData.forEach((cat) => {
			columns[cat.column ? cat.column - 1 : 0].push(cat);
		});

		return (
			<div className='portal'>
				<div className='portal-map-main'>
					<div className='wrapper-content-portal-map-main'>
						<div className='portal-map-header'>
							<div className='first-row'>
								<LogoRoot />
								<div className='lang-lost-hear-button'>
									<div className='portal-lang-bad-vision'>
										<div className='portal-lang'>
											<Language />
										</div>
										<div className='portal-bad-vision'>
											<BadVision />
										</div>
									</div>
									<Link to='/citizens-appeals' className='submit-an-uppeal' alt={t('appeals.submitAppeal')}>
										<Button color='grey' size='small' prependIcon={<AppealIcon />}>
											<p className='submit-an-uppeal-text'>{t('appeals.submitAppeal')}</p>
										</Button>
									</Link>
								</div>
							</div>
							<div className='second-row desktop-menu'>
								<Menu className={classNameMenu} />
								<div className='portal-modal-close' onClick={store.ui.onClosePortalMap}>
									<CloseLogo />
									<div>{t('close')}</div>
								</div>
							</div>
						</div>
						<div className='portal-search-container'>
							<SubmitSearchInput onSubmit={this.onSubmitSearch} placeholder={t('searchPage.portalSearch')} />
						</div>
						<div className='portal-map'>
							<div className='portals'>
								<div className='portals-upper-part'>
									<span className='portal-block-header'>{t('portal')}</span>
									<div className='portals-list'>
										{!!portals.length ? (
											portals.map((portal, index) => (
												<div className='portals-list-item-container' key={index}>
													<div className='portals-list-item-wrapper'>
														<a href={portal.link || `/${portal.module}`} className='portals-list-item' alt={portal.name || t(`sites.${portal.module}`)}>
															<div className='portals-list-item-image'>{IMAGES_MAP[portal.module] || IMAGES_MAP['all-sites']}</div>
															<div className='portals-list-item-text'>{portal.name || t(`sites.${portal.module}`)}</div>
														</a>
													</div>
												</div>
											))
										) : (
											<Loader />
										)}
									</div>
								</div>
								<div>
									<a className='portals-map-link' onClick={() => this.store.showAll()} alt={t('portalMap')}>
										{t('portalMap')}
									</a>
								</div>
							</div>
							<div className='all-portals-map'>
								<span className='portal-block-header'>{t('footer.siteMapTitle')}</span>
								<div className='portals-map-container'>
									{columns.map((column, index) => (
										<div key={index} className='col-3 portals-map-column'>
											{this.renderPortalColumn(column, IMAGES_MAP)}
										</div>
									))}
								</div>
							</div>
						</div>
						<div className='portals-bottom-buttons'>
							<Link to='/citizens-appeals' alt={t('appeals.submitAppeal')}>
								<Button color='white' prependIcon={<AppealIcon />}>
									{t('appeals.submitAppeal')}
								</Button>
							</Link>
							{/*<Button color='deep-blue' appendIcon={<LoginIcon />}>
								{t('enter')}
							</Button>*/}
						</div>
					</div>
				</div>
			</div>
		);
	}
}
