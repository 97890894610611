import React from 'react';
import './MetaInfo.scss';
import { format } from 'date-fns';
import { t } from 'i18next';

export class MetaInfo extends React.Component {
	render() {
		const { record } = this.props;

		if (!record.createdAt && !record.updatedAt) return null;

		const createdString = record.createdAt ? format(new Date(record.createdAt), 'dd.MM.yyyy HH:mm') : '-';
		const updatedString = record.updatedAt ? format(new Date(record.updatedAt), 'dd.MM.yyyy HH:mm') : '-';

		return (
			<div className='MetaInfo'>
				<div className='MetaInfo-content'>
					{record.createdAt && (
						<p className='MetaInfo-content-item'>
							{t('publish')}: <span>{createdString}</span>
						</p>
					)}
					<div className='MetaInfo-content-delimiter' />
					{record.updatedAt && (
						<p className='MetaInfo-content-item'>
							{t('updatedAt')}: <span>{updatedString}</span>
						</p>
					)}
				</div>
			</div>
		);
	}
}
