import React, { Component } from 'react';
import { observer } from 'mobx-react';
import store from 'client/store';
import t from 'i18n';
import iconGo from 'client/img/widget/icon-go.svg?url';
import './style.scss';
import ExternalLink from 'helpers/externalLink';

@observer
class WidgetVariant3 extends Component {
	render() {
		const widget = store.ui.siteWidgets.find((widget) => widget.variantId === 3);
		if (!widget) return;
		const stringWithoutTags = widget && widget.text.replace(/(<([^>]+)>)/gi, '');
		return (
			<div className='widget3'>
				<div className='photo-name-position'>
					{widget?.image ? (
						<div className='photo-container'>
							<img
								src={widget.downloadFile('image' + `?image=[{"resize":{"width":80, "height":80}}]`)}
								style={{ width: 80, height: 80, borderRadius: 100, overflow: 'hidden', marginRight: 16 }}
								className='photo'
								alt={widget.image}
							/>
						</div>
					) : (
						<div className='photo empty'>{t('banner.noImage')}</div>
					)}
					<div className='name-position'>
						<div className='name'>{widget.personsFullName}</div>
						<div className='position'>{widget.position}</div>
					</div>
				</div>
				<div className='text-container'>
					<div className='text' dangerouslySetInnerHTML={{ __html: stringWithoutTags }} />
					<ExternalLink to={widget.link1} className='link-header-container' alt={widget.linkHeader1}>
						<div className='link-header'>{widget.linkHeader1}</div>
						<img src={iconGo} alt={widget.linkHeader1} className='go' />
					</ExternalLink>
				</div>
			</div>
		);
	}
}

export default WidgetVariant3;
