import React from 'react';
import { Link } from 'react-router-dom';
import { Picture } from 'components';
import './materialCardSidebar.scss';

export class MaterialCardSidebar extends React.Component {
	render() {
		const { record } = this.props;
		if (!record) return;
		const stringWithoutTags = record.description?.replace(/(<([^>]+)>)/gi, '');
		return (
			<Link to={`/about-republic/${record.id}`} className='about-republic-card-sidebar' alt={record.title}>
				<div className='title-description'>
					<div className='title'>{record.title}</div>
					<div className='description' dangerouslySetInnerHTML={{ __html: stringWithoutTags }} />
				</div>
				<div className='card-image'>
					<Picture src={record.downloadFile('image')} width={80} alt={record.title} />
				</div>
			</Link>
		);
	}
}
