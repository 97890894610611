export default {
	translation: {
		ipPhone: 'IP-телефон',
		addRecord: 'Эбии суруй',
		editRecord: 'Суруллубуту көннөр',
		noData: 'Туох да көстүбэтэ',
		all: 'Барыта',
		fio: 'ФИО',
		from: 'от',
		find: 'Найти',
		publishAt: 'Киэҥ билиигэ тахсыбыт күнэ-дьыла',
		publish: 'Киэҥ билиигэ таһаарылынна ',
		note: 'Хос быһаарыы',
		archive: 'Архыыпка',
		pressCenter: 'Пресс-киин',
		actual: 'Тирээн турар суолталаах',
		acceptAt: 'Ылыллыбыт күнэ',
		number: 'Нүөмэрэ',
		datePickerPlaceholder: 'ДД.ММ.ГГ',
		byCounty: 'По районам',
		showNote: 'Хос быһаарыыны көрдөр',
		hideNote: 'Хос быһаарыыны сап',
		showNoteLigament: 'Показать примечание / связку',
		hideNoteLigament: 'Скрыть примечание / связку',
		orderBy: 'Суортарынан араар',
		layers: 'Араҥа',
		area: 'Иэн',
		flag: 'Былаах',
		close: 'Закрыть',
		portal: 'Портал',
		portalMap: 'Карта портала',
		enter: 'Войти',
		reset: 'Сбросить',
		resetAll: 'Сбросить все',
		showMore: 'Показать еще',
		adminCenter: 'Административнай киин',
		oktmo: 'ОКТМО',
		badVision: 'Мөлтөхтүк көрөөччүлэргэ версия',
		banners: 'Баннердар',
		author: 'Ааптар',
		code: 'Куод',
		default: 'Эрдэ олохтоммут бэрээдэгинэн',
		upload: 'Хачайдаан ыл',
		material: 'Матырыйаал',
		selectImage: 'Выберите изображение',
		addImage: 'Добавление изображения',
		add: 'Добавить',
		save: 'Сохранить',
		saveAndExit: 'Сохранить и выйти',
		rating: {
			one: 'сыанабыл',
			two: 'сыанабыллар',
			five: 'сыанабылларын',
		},
		date1: 'Күнүттэн ыла',
		date2: 'Күнүгэр диэри',
		info: 'Иһитиннэрии',
		putRating: 'Баһаалыста, бэйэҥ сыанаҕын туруор',
		view: {
			title: 'Көрүү',
			plural: 'Көрүүлэр',
		},
		county: {
			title: 'Оройуон ',
			plural: 'Оройуоннар ',
			select: 'Оройуону тал',
			administrativeCenter: 'Административнай киин',
			admCenter: 'Адм. киин',
			square: 'Иэн',
			includes: 'Киллэрэр',
			population: 'Нэһилиэнньэ ',
			sitesOfThisArea: 'Бу оройуон саайтара',
		},
		searchPage: {
			article: 'Сонуннар',
			event: 'Буолбут түгэннэр',
			vacancy: 'Үлэ аһаҕас миэстэтэ',
			questionAnswer: 'Ыйытыы-харда',
			linksFeedLink: 'Туһалаах матырыйаал',
			album: 'Медиа',
			document: 'Докумуоннар ',
			searchResult: 'Көрдөөһүн түмүгэ',
			portalSearch: 'Порталга көрдөөһүн',
			goToSection: 'Салааҕа көс',
			chapter: 'раздел',
			allResults: 'Салааҕа булуллубут бары түмүктэр',
		},
		password: {
			title: 'Пароль',
		},
		record: {
			saved: 'Суруллубут киирдэ',
			deleted: 'Суруллубут сотулунна',
		},
		audit: {
			title: 'Журналирование',
			model: 'Модель',
			action: 'Действие',
			create: 'Создание',
			delete: 'Удаление',
			update: 'Обновление',
			select: 'Выберите запись',
			data: 'Данные записи',
			owner: 'Пользователь',
			hidden: '*скрыто*',
			objectId: 'ID Объекта',
		},
		user: {
			title: 'Пользователь',
			plural: 'Пользователи',
			blocked: 'Заблокирован',
			position: 'Должность',
			lastAuthDate: 'Дата последней авторизации',
			create: 'Новый пользователь',
			profile: 'Профиль',
			email: 'E-mail',
			emailVerified: 'E-mail подтвержден',
			name: 'Логин',
			firstName: 'Имя',
			lastName: 'Фамилия',
			middleName: 'Отчество',
			leftComment: 'оставил(а) комментарий',
			createdTask: 'создал задачу',
			telegram: 'Telegram ID',
			username: 'Логин',
			password: 'Пароль',
			avatar: 'Аватар',
			fullName: 'ФИО',
			organizationName: 'Организация',
			searchFioPositionOrganization: 'Поиск по ФИО, должности, организиации',
			canAccess: 'Пользователю будут доступны',
			allData: 'все данные',
			limitRegion: 'данные по региону:',
			limitCounty: 'данные по району:',
			languages: 'Редактор языков',
			limitCity: 'данные по городу:',
			countyName: 'Район',
			cityName: 'Город/нас.пункт',
			error: {
				email: {
					custom: {
						email: 'Неверный e-mail',
					},
					uniqueness: 'Пользователь с этим e-mail уже существует',
					verifySent: 'На e-mail отправлено письмо для подтвержения',
				},
			},
		},
		role: {
			title: 'Роль',
			plural: 'Роли',
			create: 'Новая роль',
			created: 'Созданo',
			modified: 'Изменено',
			name: 'Код',
			description: 'Название',
			isSaving: 'Права сохраняются...',
		},
		footer: {
			siteMapTitle: 'Саайт каартата',
			portalsTitle: 'Портаал',
		},
		siteType: {
			plural: 'Саайт көрүҥнэрэ',
			title: 'Саайт көрүҥэ',
			parent: 'Төрүт көрүҥэ',
		},
		copyright: {
			sendAppeal: 'Этиитэ (туруорсуута) киллэр',
			login: 'Киир',
			policy: 'Кистэлэҥҥэ тутуу бэлиитикэтэ',
			userAgreement: 'Туһанааччыны кытта түһэрсиллэр сөбүлэһии',
			allRightsReserved: '© Бары быраап көмүскэллэннэ',
		},
		openData: {
			title: 'Аһаҕас чахчылар',
			dataLibrary: 'Чахчылар бибилэтиэкэлэрэ',
			dataSetsFound: 'Чахчылар холбоһуктара булулунна',
			openDataSearch: 'Аһаҕас чахчыларынан көрдөөһүн',
			responsiblePerson: 'Эппиэттээх сирэй',
			phoneResponsiblePerson: 'Эппиэттээх сирэй төлөпүөнэ',
			emailResponsiblePerson: 'Эппиэттээх сирэй е-mail',
			agency: 'Уорган',
			identificationNumber: 'Идентификациялыыр нүөмэр',
			publishAt: 'Киэҥ билиигэ таһаарылынна',
			updatedAt: 'Бүтэһик уларытыы',
			versionGuidelines: 'Методическай сүбэ версия',
			chapter: 'Салаа ',
			actualDataAt: 'Тирээн турар суолталаммыт күнэ-дьыла',
			dataSet: 'Чахчылар холбоһуктара',
			passport: 'Пааспар',
			structure: 'Тутул',
			table: 'Табылыысса',
			generalAssessment: 'Уопсай сыанабыл',
			pleaseYourRating: 'Баһаалыста, бэйэҥ сыанаҕын туруор',
			byUpdateDateAscending: 'Саҥардыллыбыт күнүнэн (улаатан иһиитинэн)',
			byUpdateDateDescending: 'Саҥардыллыбыт күнүнэн (кыччаан иһиитинэн)',
			text: 'Описание набора данных',
			format: 'Формат набора открытых данных',
			descriptionStructure: 'Описание структуры набора открытых данных',
			uriSelect: 'Гиперссылка (URL) на набор',
			uriOpenData: 'Гиперссылки (URL) на версии открытых данных',
			uriStructure: 'Гиперссылки (URL) на версии структуры набора данных',
			name: 'Заголовок',
		},
		aboutRepublic: {
			title: 'Өрөспүүбүлүкэ туһунан ',
			material: 'Матырыйааллар',
			searchMaterial: 'Матырыйаалларынан көрдөөһүн',
			infoAboutRepublic: 'Өрөспүүбүлүкэ туһунан иһитиннэрии',
			geography: 'География',
			historyTime: 'Устуоруйа уонна кэм',
			personality: 'Тустаах дьон олоҕун-үлэтин туһунан иһитиннэрии',
			cultureArt: 'Култуура уонна ускуустуба',
			religion: 'Итэҕэл',
			humanSociety: 'Киһи уонна уопсастыба ',
			scienceNature: 'Билим уонна Айылҕа үөрэҕэ',
			technics: 'Тиэхиньикэ',
			sport: 'Успуорт',
			economy: 'Экэниэмикэ',
			education: 'Үөрэх',
			newOnMaterials: 'Матырыйаалларга баар саҥа киириилэр',
			generalInformation: 'Уопсай сибидиэнньэ',
		},
		aboutrepubliccategory: {
			title: 'Категория',
		},
		republicMaterial: {
			title: 'Өрөспүүбүлүкэ матырыйаала',
			plural: 'Өрөспүүбүлүкэ матырыйааллара',
		},
		citizensAppeals: {
			title: 'Гражданнар туруорсуулара',
			appeal: 'Туруорсуу',
			firstName: 'Араспаанньата',
			name: 'Аата',
			middleName: 'Аҕатын аата',
			withoutMiddleName: 'Аҕата биллибэт',
			coAuthorWithoutMiddleName: 'Хос ааптар аҕатын аата биллибэт',
			theme: 'Тиэмэ',
			recipient: 'Аадырыстаах',
			result: 'Түмүк',
			phone: 'Билсэр төлөпүөн',
			email: 'Электроннай почта аадырыһа',
			enterEmail: 'Электроннай почта аадырыһын киллэр',
			organization: 'Тэрилтэ аата',
			addCoAuthor: 'Хос ааптары эп',
			subjectAppeals: 'Туруорсуу тиэмэтэ',
			statusAppeals: 'Туруорсуу туруга',
			textAppeals: 'Туруорсуу тиэкиһэ',
			legalEntity: 'Юридическай сирэй ',
			supported: 'Өйөннө',
			partialSupported: 'Сорҕото өйөннө (өскөтүн туруорсууга хас да ыйытыы киирбит буоллаҕына)',
			explained: 'Быһаарыы бэрилиннэ',
			hasTheDeadline: 'Туруорсуугун көрүү түмүгүнэн ылыллыбыт быһаарыыны  толоруу болдьоҕо быһаарыллыбыт дуо?',
			specifyDeadline: 'Туруорсуугун көрүү түмүгүнэн ылыллыбыт быһаарыыны толоруу болдьоҕун ый (баар буоллаҕына)',
			evaluateTheWork: 'Туруорсуугун көрүүгэ судаарыстыбаннай былаас толорор уорганын (олохтоох салайыныы уорганын) үлэтин сыаналаа',
			naturalPerson: 'Физическэй сирэй',
			appealSent: 'Туруорсуу ыытылынна',
			authorAppeal: 'Туруорсуу ааптара',
			buttonForCollectiveAppeals: 'Кэлэктиибинэн туруорсууга аналлаах кнопка',
			postalAddress: 'Почтатын аадырыһа',
			thankYouVeryMuch: 'Улахан махтал!',
			yourLetterSent: '"Суругуҥ ыытылынна уонна киирбит түгэниттэн үс күн иһигэр булгуччу регистрацияланыахтаах"',
			answerTheQuestions: 'Туруорсуугун көрүү түмүгүнэн  ыйытыыларга хардар',
			answerTheQuestionsAdmin: 'Туруорсууну көрүү түмүгүнэн ыйытыыларга хардар',
			whatDecisionWasMade: 'Туруорсуугун көрүү түмүгүнэн ханнык быһаарыы ылылынна?',
			fileSizeNotExceed10Mb: 'Файл кээмэйэ 10Мб куоһарыа суохтаах',
			agreementWithTheRules: 'Туруорсууну киллэрии быраабылаларын кытта сөбүлэһэбин',
			formEvaluationOfResults: 'Туруорсуулары көрүү түмүгүн сыаналааһын киэбэ',
			coAuthorAppeal: ' Туруорсуу хос ааптара',
			removeCoAuthor: 'Хос ааптары сотон кэбис',
			fillFormCitizensElectronicAppeal: 'Кыһалҕаҕын быһаарарга анаан гражданнар туруорсууларын электроннай көрүҥүн толор',
			surnameFirstNamePatronymicTheOfficial: 'Сололоох сирэй Араспаанньата, Аата, Аҕатын Аата',
			selectFromTheList: 'Кэрискэттэн тал',
			inorderProtectAgainstThePossibleIntroductionOfVirusPrograms:
				'Вирустаах бырагыраамалары киллэрииттэн көмүскэнэр инниттэн манна тиэкиһи куопуйалыыр уонна көһөрөр бобуллар',
			consentToDataProcessing:
				'Мин бэйэм туспунан чахчылары ситэри оҥорорго сөбүлэҥмин бигэргэтэбин. Мин туспунан чахчылар хайдах хараллаларын уонна туттуллаларын туһунан сиһилии',
			personalInformationIsStoredAndProcessed:
				'Тус чахчыларга хабааннаах иһитиннэрии тус чахчылар тустарынан Россия сокуоннарын ирдэбиллэригэр сөп түбэһиннэрэн хараллар уонна ситэри оҥоһуллар. Туруорсууну көрөргө туруорсууга баар сибидиэнньэлэри, ону тэҥэ гражданин тус олоҕун таарыйар чибидиэнньэлэри кини көҥүлэ суох тарҕатыы бобуллар.',
			isNotDisclosureOfInformation:
				'Туруорсууга баар боппуруостары быһаарар боломуочуйалаах судаарыстыбаннай уоргаҥҥа, олохтоох салайыныы уорганыгар эбэтэр сололоох сирэйгэ суругунан туруорсууну ыытыы туруорсууга баар сибидинньэлэри тарҕатыынан буолбат.',
			totalApplicationsSubmitted: 'СӨ дьиҥ таһымнаах иһитиннэриитин портаалын көмөтүнэн бэриллибит туруорсуу барыта',
			responsesToRequests: 'Туруорсууларга хардалар',
			hasTheDecisionMade: 'Туруорсуугун көрүү түмүгүнэн ылыллыбыт быһаарыы толорулунна дуо?',
			measuresTaken: 'Дьаһал ылылынна',
			measuresBeingTaken: 'Дьаһаллар ылыллаллар (болдьох уһатылынна)',
			registeredAtRK: 'РК регистрацияламмыт күнэ-дьыла',
			numberRKinESED: 'ЕСЭД-кэ РК №',
			dateOriginRKinESED: 'ЕСЭД-кэ РК барбыт күнэ',
			outgoingNumberRKinESED: 'ЕСЭД-кэ РК барбыт №',
			typeResponse: 'Харда көрүҥэ',
			electronically: 'Электроннай көрүҥүнэн',
			inWriting: 'Суругунан',
			enterMessageOnTheQuestionOfInterest: 'Интэриэһиргиир боппуруоскунан иһитиннэриигин киллэр',
			enterYorFamily: 'Араспаанньаҕын киллэр',
			socialStatus: 'Социальнай балаһыанньа ',
			executor: 'Толорооччу',
			exportInExcel: 'Ексельгэ көһөр',
			summaryReport: 'Түмүллүбүт отчуот',
			sendToESED: 'ЕСЭДкэ ыыт',
		},
		pageDescription: {
			plural: 'Сирэйд ойуулааһын',
			title: 'Сирэйи ойуулааһын',
		},
		managements: {
			pageTitle: 'Бырабыыталыстыба састааба',
			tabGroup: 'Салайааччылар бөлөхтөрө',
			tabList: 'Салалта',
			photo: 'Хаартыска',
			plural: 'Салалта',
			title: 'Салайааччы',
			fio: 'ФИО',
			compositionGovernment: 'Состав правительства',
			dateOfBirth: 'Төрөөбүт күн',
			position: 'Соло',
			education: 'Үөрэх',
			biography: 'Олоҕу ойуулаан суруйуу',
			phone: 'Телефон приемной',
			supervisedDirections: 'Курируемые направления',
			legalActs: 'Правовые акты',
			otherInformation: 'Иные сведения',
			preRegistrationPhone: 'Предварительная запись по тел. IP',
			email: 'E-mail для официальной переписки',
			laborActivity: 'Үлэ-хамнас',
			descriptionOfActivity: 'Үлэни-хамнаһы ойуулаан суруйуу',
			departmentalAwards: 'Биэдэмистибэ наҕараадалара уонна үтүөлээх ааттар',
			contacts: 'Контактар',
			address: 'Почтовый адрес',
			schedule: 'Приемнуур график',
			assistant: 'Көмөлөһөөччү',
			notFound: 'Салайааччы булуллубата',
		},
		month: {
			title: 'Ый',
			january: 'Тохсунньу',
			february: 'Олунньу',
			march: 'Кулун тутар',
			april: 'Муус устар',
			may: 'Ыам ыйа',
			june: 'Бэс ыйа',
			july: 'От ыйа',
			august: 'Атырдьах ыйа',
			september: 'Балаҕан ыйа',
			october: 'Алтынньы',
			november: 'Сэтинньи',
			december: 'Ахсынньы',
		},
		supportMeasure: {
			title: 'Өйөбүл дьаһаллара',
			plural: 'Өйөбүл дьаһаллара',
			all: 'Барыта',
			searchBySupportMeasure: 'Поиск по мерам поддержки',
			descriptionMeasure: 'Дьаһалы ойуулааһын',
			additionalInfo: 'Эбии иһитиннэрии',
			evaluateMeasure: 'Дьаһалы сыаналаа',
			usefulInfo: 'Бу иһитиннэри эйиэхэ туһалаата дуо?',
			interestingAndUseful: 'Интэриэһинэй уонна туһалаах',
			anotherColumns: 'Атын ыстатыйалар',
			goToOtherMeasures: 'Атын дьаһалларга көһүү',
			formsArrayFirst: 'мера поддержки',
			formsArraySecond: 'меры поддержки',
			formsArrayThird: 'мер поддержки',
		},
		siteManagment: {
			plural: 'Управление сайтом',
			title: 'Управление сайтом',
		},
		event: {
			plural: 'Буолбут түгэн халандаара',
			title: 'Буолбут түгэн халандаара',
		},
		eventtype: {
			title: 'Тип события',
		},
		poster: {
			title: 'Афиша',
		},
		events: {
			announcement: 'Анонсалар',
			calendar: 'Халандаар',
			list: 'Кэрискэ/испииһэк',
			events: 'Буолбут түгэннэр',
			holidays: 'Бырааһынньыктар',
			noEvents: 'Буолбут түгэн булуллубата',
			noHolidayEvents: 'Бырааһынньык булуллубата',
			formsArrayFirst: 'событие',
			formsArraySecond: 'события',
			formsArrayThird: 'событий',
			organizer: 'Организатор',
			executionType: 'Тип проведения',
		},
		eventstatus: {
			title: 'Статус',
		},
		media: {
			image: 'Ойуу/уруһуй',
			video: 'Видео',
			audio: 'Аудио',
			plural: 'Медиахранилище',
			searchByMedia: 'Поиск по медиахранилищу',
			formsArrayFirst: 'медиаматериал',
			formsArraySecond: 'медиаматериала',
			formsArrayThird: 'медиаматериалов',
			nothingFound: 'К сожалению, по вашему запросу ничего не найдено',
		},
		questionAnswer: {
			title: 'Ыйытыы-хоруй',
			plural: 'Ыйытыы-хоруй',
			source: 'Источник',
			noDeleteSubCategory: 'Данную подкатегорию нельзя удалить, потому что она указана в записях Вопрос-Ответ',
		},
		allSites: {
			lineTitle: 'Саайтар бары',
			plural: 'Саайтар бары',
			short: 'Бары',
			popularTitle: 'Портаал биллэр саайтара',
			goToDepartment: 'Биэдэмистибэҕэ көһүү',
			allPortalSites: 'Портаал саайтара бары',
			popularSitesLineTitle: 'Портаал биллэр саайтара',
			aboutPart: 'Салаа туһунан',
			aboutPartTitle: 'Бу салааҕа Портаал иһинээҕи саайтарга көһүөххүн сөп',
			aboutPartText:
				'Урукку өттүгэр Портаалынан туһамматах дьоҥҥо бу курдук билиһиннэрэр тиэкиһи суруйуохха сөп. Туһанааччыларга бу курдук: Портаал Sakha.gov.ru Портаал сүрүн саайтыттан уонна Саха Өрөспүүбүлүкэтин дьиҥ таһымнаах портаалыгар саайтаах тэрилтэлэр, сололоох сирэйдэр, юридическай сирэйдэр - субъектар саайтарыттан турар. Субъектар саайтарынан СӨ Ил Дарханын саайта, СӨ Бырабыыталыстыбатын саайта, СӨ Бырабыыталыстыбатын Бэрэссэдээтэлин саайта, СӨ Бырабыыталыстыбатын Бэрэссэдээтэлин солбуйааччыларын саайтара, Аһаҕас бырабыыталыстыба портаала, судаарыстыбаннай былаас толорор уорганнарын саайтара, СӨ судаарыстыбаннай былааһын уорганнарын уонна олохтоох салайыныы уорганнарын саайтара, судаарыстыбаннай былаас уорганарыгар бас бэринэр тэрилтэлэр саайтара, олохтоох салайыныы уорганнарыгар бас бэринэр тэрилтэлэр саайтара буолаллар. Портаал туспа биэдэмистибэлэр хос доменнарыгар уонна судаарыстыбаннай былаас уорганнарыгар уонна олохтоох салайыныы уорганнарыгар бас бэринэр тэрилтэлэр саайтарыгар киириини хааччыйыахтаах.',
			populars: 'Киэҥник биллэр',
		},
		usefulLinks: {
			title: 'Туһалаах ресурсалар',
		},
		superBlock: {
			title: 'Супер блок',
			unit: 'Блок',
			tabTitle: 'Киллэһик аата',
			icon: 'Иконканы тал',
			selectTheList: 'Кэрискэттэн тал',
			selectTheNews: 'Сонун',
			selectTheEvent: 'Бэлиэ түгэн (тэрээһин)',
			selectTheQuestion: 'Ыйытыы-хоруй',
		},
		acl: {
			search: 'Поиск по названию или описанию модели',
			title: 'Право',
			plural: 'Права',
			create: 'Новое право',
			principalType: 'Вид субъекта',
			app: 'Приложение',
			role: 'Роль',
			user: 'Пользователь',
			accessType: 'Действие',
			read: 'Чтение',
			write: 'Запись',
			execute: 'Выполнение',
			all: 'Все',
			principalId: 'Субъект',
			model: {
				title: 'Модель',
				user: 'Пользователи',
				project: 'Проекты',
				person: 'Персоны',
				description: 'Описание',
			},
			permission: 'Право',
			allow: 'Разрешить',
			deny: 'Запретить',
			property: 'Свойство',
			allowAll: 'Разрешить все',
			list: 'Список прав',
		},
		roleMapping: {
			title: 'Назначение роли',
			plural: 'Назначенния ролей',
			create: 'Новое назначение роли',
		},
		news: {
			title: 'Сонуннар',
			last: 'Кэлиҥҥилэр',
			capital: 'Киин куорат',
			county: 'Оройуоннар',
			republic: 'Өрөспүүбүлүкэ',
			inCountry: 'Дойдуга',
			inWorld: 'Аан дойдуга',
			ulus: 'Улуус',
			rubric: 'Рубрикалар',
			appurtenance: 'Туохха сыһыаннааҕа',
			importantNews: 'Дьоһун суолталаах сонуннар',
			allImportantNews: 'Өрөспүүбүлүкэ дьоһун суолталаах сонуна барыта',
			showImportantNewsOnPanel: 'Панелга "Дьоһун суолталаах сонуннар" диэни көрдөр',
			showMore: 'Өссө көрдөр',
			newsTop: 'Сүрүн сонуннар',
			newsByTheme: 'Тиэмэ сонуннара',
			searchByNews: 'Сонуннарга көрдөөһүн',
			noNewsFound: 'Новостей не найдено',
			mainThing: 'Сүрүн',
			signatureAuthorPhoto: 'Хаартыска аннынааҕы сурук эбэтэр ааптара',
			showOnSupportWidget: 'Отображать в виджете - "Меры поддержки"',
			formsArrayFirst: 'материал',
			formsArraySecond: 'материала',
			formsArrayThird: 'материалов',
			showNews: 'Посмотреть новости',
		},
		vacancy: {
			unifiedDatabaseOfVacancies: 'Аһаҕас турар үлэ миэстэтин биир кэлим баазата',
			title: 'Аһаҕас турар үлэ миэстэтэ',
			searchByParameter: 'Поиск по параметру',
			organizationInitiatorTitle: 'Тэрилтэ/Көҕүлээччи',
			titles: 'Аһаҕас турар үлэ миэстэлэрэ',
			plural: 'Аһаҕас турар үлэ миэстэлэрэ',
			basis: 'Төрүтэ ',
			branch: 'Тэрилтэ салаата',
			contactPerson: 'Ситимниир сирэй',
			linkContactPerson: 'Ситимниир сирэйгэ сигэ',
			linkButton: '"Сэҥээрбиккин биллэр" кнопкаҕа сигэ',
			education: 'Үөрэх',
			primarySourceSite: 'Төрүт саайт',
			information: 'Иһитиннэрии',
			linkBasis: '"Төрүтэ" диэҥҥэ сигэ',
			respond: 'Сэҥээрбиккин биллэр',
			totalVacancy: 'Всего вакансий: ',
			shortDescription: 'Аһаҕас турар үлэ миэстэтин кылгас ойуулааһын',
			showVacancyOnPanel: 'Панелга "Аһаҕас турар үлэ миэстэтэ" диэни көрдөр',
			workExp: 'Үлэ уопута',
			showAllVacancies: 'Смотреть все вакансии',
			lastVacancies: 'Последние вакансии',
		},
		vacancystatus: {
			title: 'Статус',
		},
		searchInput: {
			searchByName: 'Аатынан көрдөөһүн',
			submit: 'Көрдөө',
			advanced: 'Тэнитэн көрдөөһүн',
			fast: 'Түргэн көрдөөһүн',
			placeholder: 'Көрдөөһүн',
			placeholderPhonebook: 'Төлөпүөн ыйынньыгынан көрдөөһүн',
			placeholderDocumentsAcceptBody: 'Поиск по документам, его номеру и принимающему органу',
			placeholderAllSites: 'Тэрилтэ эбэтэр биэдэмистибэ саайтын көрдөөһүн',
		},
		apartment: 'Кыбартыыра/офис',
		startDate: 'Саҕаланар күнэ-дьыла',
		endDate: 'Бүтэр күнэ-дьыла',
		information: 'Иһитиннэрии',
		settings: 'Настройкалар',
		delete: 'Сотторуллар дуо?',
		day: 'Күн',
		year: 'Сыл',
		tag: {
			title: 'Тег',
			plural: 'Тегтэр',
			all: 'Тег барыта',
		},
		page: {
			plural: 'Сирэйдэр',
			title: 'Сирэй',
		},
		interactiveMap: {
			title: 'Интерактивнай каарта',
			goToSite: 'Саайка көһүү',
			lastNewsCounty: 'Оройуон тиһэх сонуннара',
		},
		analytics: 'Аналитика',
		backToSection: 'Салааҕа төннүү ',
		backToMainPage: 'Сүрүн сирэйгэ төннүү',
		category: 'Категория',
		categories: 'Категориялар',
		subCategory: 'Хос категория',
		contacts: 'Контактар',
		cover: 'Тас өттө',
		color: 'Дьүһүнэ',
		colorRGB: 'Цвет (RGB)',
		colorHEX: 'Цвет (HEX)',
		colorTextRGB: 'Цвет текста (RGB)',
		colorTextHEX: 'Цвет текста (HEX)',
		downloadFile: 'Файлы хачайдаа',
		download: 'Скачать',
		fileName: 'Файл аата',
		fileSize: 'Файл кээмэйэ',
		goPortal: 'Портаалга көһүү',
		house: 'Дьиэ',
		popular: 'Киэҥник биллэр',
		pupularServices: 'Биллэр сервистэр',
		open: 'Арый',
		yes: 'Оннук ',
		no: 'Суох',
		share: 'Тарҕат',
		interesting: 'Интэриэһинэй',
		application: 'Сыһыарыылар',
		watch: 'Көр',
		link: 'Сигэ',
		linkURL: 'URL-сигэлэр',
		linkHeaderURL: 'Сирэйгэ/салааҕа сигэ аата',
		linkHeader: 'Сигэ аата',
		locality: 'Нэһилиэнньэлээх пуун',
		pageChapter: 'сирэйгэ/түһүмэххэ',
		postcode: 'Индекс',
		textButton: 'Кнопка тиэкиһэ',
		button: 'Кнопка',
		date: 'Күнэ-дьыла',
		date0: 'Бэчээккэ тахсыбыт күнэ-дьыла ',
		updatedAt: 'Бүтэһик уларыйыы',
		createdAt: 'Создание',
		attachFile: 'Файлы сыһыар ',
		description: 'Ойуулааһын',
		descriptionNecessarily: 'Ойуулааһын (булгуччу)',
		fullName: 'Араспаанньата Аата Аҕатын Аата',
		image: 'Ойуу',
		initiator: 'Көҕүлээччи',
		icon: 'Иконка',
		photo: 'Хаартыска',
		primarySourceSite: 'Төрүт саайт',
		linkPrimarySourceSite: 'Төрүт саайка киирэр сигэ',
		loading: 'Хачайдана турар...',
		main: 'Сүрүн ',
		read: 'Аах',
		readMore: 'Салгыы аах',
		registered: 'Регистрацияламмыт',
		received: 'Киирбит',
		receivedWith: 'Киирбит (күнтэн)',
		receivedBy: 'Киирбит (күҥҥэ диэри)',
		showOnMain: 'Сүрүн сирэйгэ көрдөр ',
		see: 'Көр',
		select: 'Тал',
		send: 'Ыыт',
		status: 'Статус',
		street: 'Уулусса',
		statistics: 'Статистика',
		size: 'Кээмэйэ',
		back: 'Кэннин диэки',
		title: 'Аата',
		theme: 'Тиэмэтэ',
		type: 'Тиибэ',
		types: 'Тииптэрэ',
		city: 'Куорат',
		cityCountyWeatherIndex: 'Город - Район - Индекс метеостанции',
		header: 'Аата',
		headerNecessarily: 'Аата (булгуччу)',
		text: 'Тиэкис',
		position: 'Солото',
		fax: 'Факс',
		site: {
			title: 'Саайт',
			plural: 'Сайты',
			isMain: 'Сүрүн саайт',
			isMain3: 'Үс сүрүҥҥэ ',
			allOrganizationSites: 'Тэрилтэ бары саайтыгар',
			creating: 'Саайтары оҥоруу',
			error: 'Ошибка при создании сайта',
		},
		appeals: {
			title: 'Туруорсуу',
			submitAppeal: 'Туруорсуута киллэр',
			numberOfRequest: 'Туруорсуу ахсаана',
			shortDescription: 'Туруорсуу кылгас ойуулааһына',
			showAppealsOnPanel: 'Панелга "Туруорсуулар" диэни көрдөр',
		},
		broadcast: {
			title: 'Трансляция',
			titles: 'Трансляция',
			dateStartBroadcast: 'Трансляция саҕаланыан иннинээҕи күн-дьыл',
			linkBroadcast: 'Трансляцияҕа сигэ',
			shortDescription: 'Трансляцияны кылгас ойуулааһын',
			showBroadcastOnPanel: 'Панелга "Трансляция" диэни көрдөр',
		},
		gisMap: {
			title: 'Гис-каарта',
			showMapOnPanel: 'Панелга "Гис-каарта" диэни көрдөр',
			mapOfRepublic: 'Өрөспүүбүлүкэ ГИС-каартата',
		},
		comment: {
			title: 'Быһааран биэрии',
			postTitle: 'Быһааран биэр',
			post: 'Ыыт',
			text: 'Текст комментария',
			plural: 'Быһааран биэриилэр',
			confirmDelete: 'Быһааран биэриини сотторуллар дуо?',
			emptyAuthor: 'Ааккын киллэр',
			emptyText: 'Тиэкистэ киллэр (кырата 3 символ)',
		},
		widget: {
			title: 'Виджет',
			overviewPanel: 'Түмэн көрүү панела',
			quickAccessBar: 'Түргэнник киирии панела',
			systemWidget: 'Тиһик виджеттара',
			usersWidget: 'Туһанааччы виджеттара',
			userWidget: 'Туһанааччы виджета',
			showUserWidgetOnPanel: 'Панелга "Туһанаачы виджета" диэни көрдөр',
			iconUserWidget: 'Иконка',
			shortDescription: 'Кылгас ойуулааһын',
			imageForSample: 'Көстүү холобур быһыытынан',
			numberOfSitesOnPortal: 'Портаалга саайт ахсаана',
			sitesOnPortal: 'саайтар портаалга',
			showSitesOnPanel: 'Панелга "Портаалга саайт ахсаана" диэни көрдөр',
			plural: 'Виджеттар',
			variant: 'Барыйаан',
			widget1: '1 барыйаан',
			widget2: '2 барыйаан',
			widget3: '3 барыйаан',
			widget4: '4 барыйаан',
			widget5: '5 барыйаан',
			selectWidgetVersion: 'Виджет версиятын тал',
			version: 'Версията',
			backgroundUnderWidget: 'Иһинээҕитэ үчүгэйдик көстөрүгэр анаан виджет кэннинээҕи көстүү (бекграунд)',
			warningOneWidget: 'Бу сурук көстөр, ол аата сүрүн сирэйгэ ханнык эрэ виджет номнуо көстөн турар (бииртэн элбэҕи талыллыбат)',
		},
		feedback: {
			post: 'Ыыт',
			emptyAuthor: 'Ааккын киллэр',
			emptyEmail: ' e-mail аадырыскын киллэр',
			emptyReason: 'Суруйар төрүөккүн тал',
			emptyTitle: 'Тиэмэтин киллэр (кырата үс бэлиэ баар буолуохтаах)',
			emptyText: 'Тиэкискин киллэр (кырата үс бэлиэ баар буолуохтаах)',
			plural: 'Хардата',
		},
		banner: {
			plural: 'Баннердар',
			title: 'Баннер',
			noImage: 'туох да көстүбэт',
			attention: 'Саайка баннер холбоммут буоллаҕына, бүтэһик холбоммут баннер эрэ көстөр',
		},
		wind: {
			title: 'Тыал',
			speedFactor: 'Түргэнэ',
			fadeOpacity: 'Шлейф уһуна',
			particleLife: 'Өлүүскэ олоҕо',
			opacity: 'Дьэҥкирэ',
		},
		weather: {
			title: 'Күн-дьыл туруга',
			temp: 'Тэмпэрэтиирэ',
			tempDescription: 'Сиртэн 2 м үрдүккэ тэмпэрэтиирэ кээмэйэ',
			tempMeasure: '°C',
			pressure: 'Баттааһын',
			pressureDescription: 'Салгын баттааһына',
			pressureMeasure: 'рт.ст. мм',
			precipitation: 'Сөҥүүлэр',
			precipitationDescription: '3 чаас устатыгар сөҥүү мунньуллуута',
			precipitationMeasure: 'кв. миэтэрэҕэ кг',
			humidity: 'Сииктээҕэ',
			humidityDescription: 'Сиртэн 2 м үрдүккэ салгын сиигэ',
			humidityMeasure: '%',
			pm25: 'PM2.5',
			pm25Description: 'Салгыҥҥа баар 2.5 мкм уонна онтон кыра диаметрдаах кытаанах уонна убаҕас эттиктэр ',
			pm25Measure: 'куб. миэтэрэҕэ мкг',
			pm10: 'PM10',
			pm10Description: 'Салгыҥҥа баар 10 мкм уонна онтон кыра диаметрдаах кытаанах уонна убаҕас эттиктэр',
			pm10Measure: 'куб. миэтэрэҕэ мкг',
			co: 'CO',
			coDescription: 'СО араҥатын ойуулааһына',
			coMeasure: 'куб. миэтэрэҕэ мкг',
			no: 'NO',
			noDescription: 'NO араҥатын ойуулааһына',
			noMeasure: 'куб. миэтэрэҕэ мкг',
			no2: 'NO2',
			no2Description: 'NO2 араҥатын ойуулааһына',
			no2Measure: 'куб. миэтэрэҕэ мкг',
			ozone: 'Озон',
			ozoneDescription: 'О3 араҥатын ойуулааһына',
			ozoneMeasure: 'DU',
			so2: 'SO2',
			so2Description: 'SO2 араҥатын ойуулааһына',
			so2Measure: 'куб. миэтэрэҕэ мкг',
			feeling: 'курдук',
		},
		silam: {
			title: 'Салгын киртийиитэ',
			on: 'Көрдөр',
		},
		sites: {
			news: 'Сонуннар',
			events: 'Буолбут түгэн',
			vacancy: 'Аһаҕас турар үлэ миэстэтэ',
			'question-answer': 'Ыйытыы-хоруй',
			map: 'ГИС-каарта',
			'all-sites': 'Саайтар бары',
			'support-measure': 'Өйөбүл дьаһаллар',
			'about-republic': 'Өрөспүүбүлүкэ туһунан',
			'open-data': 'Аһаҕас чахчылар',
			null: '',
			organization: 'Тэрилтэ',
			template: 'Халыып',
			documents: 'Документы',
			media: 'Медиахранилище',
			npa: 'Проекты НПА',
			discussions: 'Опросы',
			'citizens-appeals': 'Обращения граждан',
			managements: 'Руководство',
			structure: 'Структура',
			phonebook: 'Телефонный справочник',
		},
		email: {
			title: 'E-mail',
			hint: 'mail@example.com',
			field: 'Электроннай почта',
			input: 'E-mail киллэр',
			exist: 'Маннык E-mail номнуо баар',
			invalidEmailAddress: 'Неверный адрес электронной почты',
		},
		language: {
			title: 'Тыл',
			plural: 'Тыллар',
			ru: 'нуучча',
			en: 'ааҥл',
			sah: 'саха',
		},
		document: {
			title: 'Докумуон',
			plural: 'Докумуоннар',
			registry: 'Докумуоннар эридьиэстэрэ',
			acceptBody: 'Ылар уорган',
			found: 'Булуллубут докумуоннар',
			file: 'Докумуон файла',
			findDocuments: 'Найти документы',
			noFinds: 'Документы не найдены',
			ligament: 'Связка с документом',
			addLigament: 'Связать с документом',
			parentDocument: 'Вышестоящий документ',
			parentDocuments: 'Вышестоящие документы',
			childDocuments: 'Подчиненные',
			ligamentAdd: 'Добавить связку',
			noDocuments: 'Нет доступных документов',
			signinAcceptApprovalAt: 'Дата подписания / принятия / утверждения',
			signinAcceptApprovalAtBefore: 'Дата подписания / принятия / утверждения от',
			signinAcceptApprovalAtAfter: 'Дата подписания / принятия / утверждения до',
			effectiveStartAt: 'Дата вступления в силу / начала действия',
			expirationAt: 'Дата окончания действия',
			warningLigament: 'ВНИМАНИЕ: В публичной части в связках документа отображаются только записи с блока “Подчиненные документы”',
		},
		documentType: {
			title: 'Докумуон тиибэ',
			plural: 'Докумуоннар тииптэрэ',
		},
		documenttype: {
			title: 'Докумуон тиибэ',
		},
		documentSubject: {
			title: 'Тематика документа',
			plural: 'Тематики документов',
		},
		documentLigamentType: {
			title: 'Тип связки',
			plural: 'Типы связок',
		},
		organization: {
			importPhonebook: 'Тастан киллэр',
			phonebook: 'Төлөпүөн нүөмэрдэрин ыйынньыга',
			phonebookSize: 'Төлөпүөн нүөмэрдэрин ыйынньыгын кээмэйэ',
			phonebookFormsArrayFirst: 'запись',
			phonebookFormsArraySecond: 'записи',
			phonebookFormsArrayThird: 'записей',
			official: 'Сололоох сирэй (гражданнар туруорсуулара)',
			rcitOrganization: 'Төлөпүөн нүөмэрдэрин ыйынньыгар аата-суола',
			mainInfo: 'Сүрүн иһитиннэрии',
			documents: 'Докумуоннар',
			contacts: 'Контактара',
			title: 'Тэрилтэ',
			plural: 'Тэрилтэлэр',
			name: 'Тэрилтэ аата',
			fullName: 'Тэрилтэ толору аата',
			shortName: 'Кылгас аата',
			ownershipForm: 'Бас билии көрүҥэ',
			address: 'Юридическай аадырыһа',
			inputAddressPlaceholder: 'Уулуссаҥ аатын, дьиэҥ нүөмэрин киллэр',
			inputFioPlaceholder: 'Араспаанньаҕын, ааккын, аҕаҥ аатын киллэр',
			inputPhonePlaceholder: 'Билсэр нүөмэри киллэр',
			headName: 'Салайааччы АААА',
			headPosition: 'Салайааччы солото',
			inn: 'ИНН',
			ogrn: 'ОГРН',
			kpp: 'КПП',
			comment: 'Быһааран биэрии',
			externalId: 'Тас ситимҥэ тэрилтэ ID',
			externalData: 'Тас ситимтэн тэрилтэ иһинээҕитэ',
			position: 'Соло',
			structuralDivision: 'Структурное подразделение',
			description: 'Тэрилтэ туһунан иһитиннэрии',
			info: 'Сүрүн сибидиэнньэлэр',
			factAddress: 'Дьиҥ баар аадырыһа',
			secondaryInfo: 'Эбии сибидиэнньэлэр',
			headInfo: 'Салайааччы туһунан чахчылар',
			okato: 'ОКАТО',
			oktmo: 'ОКТМО',
			okved: 'ОКВЭД',
			okpo: 'ОКПО',
			okopf: 'ОКОПФ',
			okogu: 'ОКОГУ',
			okfs: 'ОКФС',
			rngi: 'РНГИ /эридьиэс нүөмэрэ',
			noStaff: 'Үлэһит булуллубата',
			noRequests: 'Сайаапка булуллубата',
			noBankAccounts: 'Баан счета булуллубата',
			contractor: 'Бэдэрээтчит',
			activityType: 'Үлэ көрүҥэ',
			group: 'Тэрилтэлэр бөлөхтөрө',
			contragentType: 'Контрагент көрүҥэ',
			type: 'Учуот эбийиэгин тиибэ',
			status: 'Тэрилтэ ыстаатыһа',
			postAddress: 'Почтаҕа ыйыллар аадырыс',
			linkTop: 'Үөһэ өттүгэр тахсарга сигэ',
			inputPlaceholder: 'Холобур: Управление',
			inputPositionPlaceholder: 'Холобур: Салайааччы',
			higherLevelOrganization: 'Үрдүкү турар тэрилтэ',
			additional: 'Дополнительно',
			structure: {
				style: 'Стилизация элементов',
				background: 'Цвет фона',
				colorText: 'Цвет текста',
				colorBranch: 'Цвет ветвей',
				apply: 'Применить',
				imageStructure: 'Изображение структуры',
				useImage: 'Использовать изображение',
				structureDescription: 'Дополнительное описание',
				saveStructureDescription: 'Сохранить описание',
				defaultStyles: 'Стили по умолчанию',
				parentItem: 'Родительский элемент',
			},
		},
		topic: {
			title: 'Рубрика',
			plural: 'Рубрикалар',
		},
		history: {
			organizationCreate: 'создал(а) организацию',
			organizationUpdate: 'изменил(а) поля',
			update: 'изменил(а)',
			to: 'на',
		},
		discussionsNPA: {
			title: 'Нуормалыыр быраап докумуоннара',
			discussionGoingOn: 'Дьүүллэһии бара турар',
			discussionExpiresSoon: 'Дьүүллэһии сотору бүтэр',
			discussionEnd: 'Дьүүллэһии түмүктэннэ',
			searchByDocument: 'Поиск по документам и его номеру',
			developmentEnd: 'Сүрүннүүр дьайыыны сыаналааһын үлэтэ түмүктэннэ',
			documentsFound: 'Булуллубут докумуоннар:',
			note: 'Хос быһаарыы',
			name: 'НБА аата',
			status: 'Ыстаатыһа',
			procedure: 'Процедурата',
			stage: 'Түһүмэҕэ',
			text: 'Ойуулааһын',
			department: 'Биэдэмистибэ',
			urlFiles: 'Сыһыарыллыбыт файлларга сигэ',
			urlResources: 'Дьүүллэһии ыытыллар сиригэр сигэ',
			type: 'Бырайыак көрүҥэ',
			address: 'Түмүгү почтанан ыытарга анаан аадырыс',
			contactPerson: 'Контактаһар сирэй',
			plural: 'НБА барыла',
			formsArrayFirst: 'документ',
			formsArraySecond: 'документа',
			formsArrayThird: 'документов',
		},
		discussionsnpastatus: {
			title: 'Статус',
		},
		discussionsNPAProcedure: {
			stages: 'Этапы',
		},
		discussions: {
			title: 'Ыйытык',
			titlePlural: 'Ыйытыктар',
			plural: 'Ыйытыктар',
			goToOther: 'Атын ыйытыктарга көһүү',
			formsArrayFirst: 'опрос',
			formsArraySecond: 'опросы',
			formsArrayThird: 'опросов',
		},
		guidelinesRegulations: {
			title: 'Руководство и регламент',
			manualsInstructions: 'Руководства, инструкции',
			checklist: 'Контрольные листы',
			externalRegulations: 'Внешние регламенты',
			nameGuideline: 'Название руководства',
			nameChecklist: 'Название контрольного листа',
			nameRegulation: 'Название регламента',
			checklistChapter: 'Контрольные листы разделов',
			addressUrl: 'URL аадырыһа',
		},
		LOGIN_FAILED: 'Сөбө суох логин/пароль',
		LOGIN_WAIT: 'Холонуу лимиитин куоһарда',
		LOGIN_EMPTY_CAPTCHA: 'Кода киирбэтэ',
		LOGIN_INVALID_CAPTCHA: 'Сөбө суох код',
		captcha: 'Хартыынаҕа баар код',
		checkCaptcha: 'Проверить капчу',
		inputCaptcha: 'Введите капчу',
		wait: 'Тохтоо',
		seconds: 'сөкүүндэ',
		noImage: 'Хаартыска суох',
		linksFeed: {
			plural: 'Сигэ лиэнтэтэ',
		},
		linksfeedcategory: {
			title: 'Категория',
		},
		cookie: {
			buttonText: 'Понятно',
			additionalTextFirst:
				'Мы используем файлы cookie для персонализации контента, предоставления функций авторизации и анализа трафика. Чтобы отключить файлы cookie, необходимо перейти в настройки веб-браузера. Однако это может ограничить работу с порталом.',
			secondTextSecond:
				'Пользуясь данным интернет ресурсом, свободно своей волей и в своих интересах пользователь даёт согласие на автоматизированную, а также без использования средств автоматизации обработку, в т.ч. обработку своих персональных данных, передачу (в т.ч. в сторонние сервисы анализа посетителей Спутник.Аналитика, Яндекс.Метрика, vk.com, ok.ru) и обработку данных о посетителе (а именно IP-адрес, предполагаемое географическое положение, версия браузера, разрешение дисплея, версия операционной системы и вспомогательного программного обеспечения, модель устройства, ресурсы, поисковые системы, фразы, баннеры, с которых был переход на сайт, список посещённых страниц и проведённое время на сайте), а именно - совершение действий, предусмотренных 152-ФЗ «О персональных данных». Если посетитель не согласен с тем, чтобы интернет-ресурс осуществлял автоматизированную обработку данных о посетителе, он может соответствующим образом установить настройки своего браузера или покинуть сайт.',
			linkText: 'файлов cookie',
		},
		article: {
			priority: 'Приоритет вывода',
			notFound: 'Новостей не найдено',
		},
		subMenu: {
			more: 'Еще',
			hide: 'Скрыть',
		},
		module: 'Модуль',
		pageToolbar: {
			requiredPages: 'Обязательные страницы',
			expandPages: 'Раскрыть все',
		},
		templateToolbar: {
			updateSites: 'Обновить на сайтах',
			sites: 'Сайты',
			selectedCountSites: 'Выбрано: {{countSites}}',
			successMessage: 'Сайты обновлены',
			error: 'Произошла ошибка',
		},
		selectAll: 'Выбрать все',
		file: {
			moveOrSelectFile: 'Переместите или выберите файл',
		},
		checklist: {
			number: '№',
			createByMenu: 'Сформировать контрольный лист',
			error: 'Ошибка обновления контрольного листа',
			successCreate: 'Контрольный лист обновлен',
			dataSection: 'Разделы сведений',
			dataSubsection: 'Подразделы сведений',
			data: 'Сведения',
			dataType: 'Вид сведений',
			publishPeriods: 'Периоды публикаций',
			controls: 'Средства контроля',
			responsibleSpecialists: 'Сведения по ответственным специалистам',
			variableLegalBasis: 'Нормативно-правовое обоснование',
			link: 'Ссылка',
			createdAt: 'Дата ввода пункта',
			errorUpdateSites: 'Ошибка обновления чеклистов сайтов',
			successUpdateSites: 'Сайты успешно обновлены',
			isRequirePage: 'Обязательная страница',
			nextDateUpdate: 'Следующая ожидаемая дата обновления',
			textPublishPeriods: 'Текст периодичности обновления',
			nextCheckDate: 'Следующая дата проверки',
			updateFrequency: 'Периодичность обновления',
		},
		export: 'Экспорт',
		reportingYear: 'Отчетный год',
		currentYear: 'Текущий год',
		nextReportingYear: 'Следующий за отчетным годом',
		lastYear: 'Прошлый год',
		documentExists: 'Документ с таким типом, принимающим органом, датой подписания и номером уже существует.',
		topicButtonGroup: {
			maxCount: 'Макс. количество активных кнопок',
			maxCountError: 'Превышено максимальное количество активных тематических кнопок',
			errorEmpty: 'Не найдена группа кнопок',
			siteTemplate: 'Шаблон сайта',
			original: 'Оригинальная группа кнопок',
			topicButtons: 'Тематические кнопки',
		},
		topicButton: {
			title: 'Тематическая кнопка',
			plural: 'Тематические кнопки',
			label: 'Текст кнопки',
			link: 'Ссылка',
			colorText: 'Цвет текста',
			colorBackground: 'Цвет фона',
			isActive: 'На главной',
			notFound: 'Тематических кнопок не создано',
			preview: 'Вид кнопки',
			order: 'Порядок',
			invalidLink: 'не верный формат URL',
			updateError: 'Произошла ошибка. Обратитесь к администратору',
			labelMaxLength: 'Макс. количество символов {{maxLength}}',
		},
		label: {
			title: 'Тег',
			plural: 'Теги',
		},
		topicCard: {
			title: 'Карточка рубрики',
			plural: 'Карточки рубрики',
			isActive: 'Отображать',
			priority: 'Порядок вывода',
			organizations: 'Рубрика доступна для организаций',
		},
		recordSelect: {
			selectCount: 'Выбрано: {{countRecords}}',
		},
		noRecord: 'Запись не найдена, или Вам не разрешено её просматривать',
		verificationCode: 'Проверочный код',
		confirm: 'Подтвердить',
		sendedVerificationCode: 'Проверочный код отправлен на указанный email',
		invalidVerificationCode: 'Неверный проверочный код',
		esiaLogin: 'Войти через ЕСИА для автозаполнения полей',
		logout: 'Выйти',
		esiaUser: 'Вы вошли как {{userFIO}}',
		siteTemplate: {
			mainMenuLimit: 'Макс. кол-во. пунктов главного меню 1-го уровня',
			mainMenuLimitError: 'Превышено максимальное количество активных разделов, отображаемых на главном меню',
		},
		pageDevelopment: 'Страница находится в разработке',
		writeAction: 'Написать',
		alt: {
			emptyImage: 'Изображение заглушка',
			like: 'Понравилось',
			dislike: 'Не понравилось',
			iconDollar: 'Иконка доллара',
			iconEuro: 'Иконка евро',
			arrowLeft: 'Стрелка влево',
			arrowRight: 'Стрелка вправо',
			playOrPause: 'Воспроизвести/пауза',
			playerPrev: 'Предыдущая запись',
			playerRewind: 'Перемотка',
			playerNext: 'Следующая запись',
			telegram: 'Иконка телеграмма',
			vk: 'Иконка Вконтекте',
			twitter: 'Иконка Twitter',
			rutube: 'Иконка RuTube',
			odnoklassniki: 'Иконка Одноклассники',
			rss: 'Иконка RSS',
			GISMap: 'ГИС-карта Республики',
			republicNews: 'Все важные новости республики',
		},
		goTo: 'Перейти',
		imageOnMain: 'Изображение на главной',
		openDataFormat: {
			name: 'Наименование',
			code: 'Код',
			title: 'Формат открытых данных',
			plural: 'Форматы открытых данных',
		},
		enterCustomVariant: 'Укажите ваш вариант ответа',
		phoneNumber: 'Телефонный номер',
		reasonFeedback: 'Причина обращения',
		themeFeedback: 'Тема обращения',
		messageFeedback: 'Текст обращения',
		activeMenuItem: 'Кол-во. активных пунктов главного меню 1-го уровня',
		npaLink: {
			title: 'Ссылка на архив НПА',
			plural: 'Ссылки на архив НПА',
			text: 'Текст кнопки',
			link: 'Ссылка',
			exists: 'Ссылка уже существует',
			maxLengthText: 'Максимальная длина строки {{strLength}}',
			invalidLink: 'Неверный формат ссылки',
			isActive: 'Активна',
		},
		notModalAccess: 'Нет прав доступа',
		widgetsPOS: {
			title: 'ПОС виджета',
			plural: 'ПОС виджеттара',
			isActive: 'ПОС виджета кодун туттар',
			insertCode: 'ПОС виджета кодун киир',
			maxWidth: 'ПОС виджета көнө кээмэйэ, %',
			maxWidthError: 'Күҥҥэ кээмэйэ: 100',
			settings: {
				title: 'ПОС баннерын салайыы',
				posMaxTopOffset: 'ПОС баннера «ПОС баннерын» көнө кээмэйэ, пиксель',
				posMinHeight: 'ПОС баннерын кыра кээмэйэ, пиксель',
				posMaxWidth: 'ПОС баннерын көнө кээмэйэ, пиксель',
				noActiveWarning: 'ПОС виджета кодун туттарыгар атыннык ({{languagesString}}). Атынныктырыыга - "ПОС виджеттара" табыгар барыҥ',
			},
		},
	},
};
