import React, { Component } from 'react';
import t from 'i18n';
import { observer } from 'mobx-react';
import store from 'client/store';
import './style.scss';

@observer
export default class WidgetVariant2 extends Component {
	render() {
		const widget = store.ui.siteWidgets.find((widget) => widget.variantId === 2);
		if (!widget) return;
		const stringWithoutTags = widget.text?.replace(/(<([^>]+)>)/gi, '');
		return (
			<div className='widget2'>
				<div className='photo-name-position'>
					{widget.image ? (
						<img
							src={widget.downloadFile('image' + `?image=[{"resize":{"width":80, "height":80}}]`)}
							style={{ width: 80, height: 80, borderRadius: 100, overflow: 'hidden', marginRight: 16 }}
							className='photo'
							alt={widget.image}
						/>
					) : (
						<div className='photo empty'>{t('banner.noImage')}</div>
					)}
					<div className='name-position'>
						<div className='name'>{widget.personsFullName}</div>
						<div className='position'>{widget.position}</div>
					</div>
				</div>
				<div className='text-container'>
					<div className='text' dangerouslySetInnerHTML={{ __html: stringWithoutTags }} />
				</div>
			</div>
		);
	}
}
