import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import StarsLeftIcon from 'img/pattern-stars-left2.svg';
import AllSitesIcon from 'img/icon-all_sites-grey.svg';
import StarsRightIcon from 'img/pattern-stars-right.svg';
import IconUpload from 'img/icon-upload.svg';

import store from 'client/store';
import t from 'i18n';
import './style.scss';
import PageDocuments from './PageDocuments';
import { NotPhone } from 'components';
import TextWithDocuments from 'components/text-with-documents/TextWithDocuments';
import { MetaInfo } from 'components/meta-info';

@withRouter
@observer
export default class PageTree extends React.Component {
	@observable tree = null;
	@observable error = null;
	@observable page = null;
	@observable mainSite = null;
	@observable documentIds = [];
	@observable isLoading = true;

	constructor(props) {
		super(props);
		this.init();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.tree.id !== prevProps.tree.id) {
			this.init();
		}
	}

	init = async () => {
		this.isLoading = true;
		try {
			this.page = await store.model.Page.findById(this.props.tree.id, { include: ['attachments'] });
			this.mainSite = (await store.model.Site.find({ where: { isMain: true, publish: true } }))?.[0];

			if (this.page && this.page.text) {
				this.documentIds = this.extractDocumentIds(this.page.text);
			}
		} catch (error) {
			this.error = error;
		}
		this.isLoading = false;
	};

	extractDocumentIds = (htmlContent) => {
		const regex = /\[document id: (\d+)\]/g;
		const documentIds = [];
		let match;
		while ((match = regex.exec(htmlContent)) !== null) {
			documentIds.push(Number(match[1]));
		}
		return documentIds;
	};

	renderNode = (node) => {
		let content;
		let pageContent = null;
		if (node.text) {
			const contentParts = <TextWithDocuments content={node.text} />;
			pageContent = <div className='article-container'>{contentParts}</div>;
		}
		let attachments = this.page && this.page.attachments();
		let pageAttachments = !!attachments?.length && (
			<div className='application-wrapper'>
				<div className='title p4'>{t('application')}</div>
				<div className='application'>
					{attachments.map((a) => (
						<a className='docs' target='_blank' href={a.downloadFile('filename')} download key={a.id} alt={a.filename}>
							<div className='file-name p2'>{a.filename}</div>
							<IconUpload />
						</a>
					))}
				</div>
			</div>
		);

		const hideParent = new RegExp(`^${node.route}\/`, '').test(store.route.path);
		content = (
			<>
				{!hideParent ? (
					<>
						{pageContent}
						{pageAttachments}
					</>
				) : null}
			</>
		);
		return content;
	};

	render() {
		if (this.isLoading) return null;
		const page = this.props.tree;
		const content = this.renderNode(page);

		return (
			<>
				<div className='search-page'>
					<NotPhone>
						<StarsLeftIcon className='stars-left' />
					</NotPhone>
					<div className='content'>
						<div className='search-title-line'>
							<span className='h1 header'>{page.title}</span>
							<div className='right templates-tabs'>
								<a href={'https://' + this.mainSite?.domain} className='portal-link' alt={t('goPortal')}>
									<AllSitesIcon />
									<div className='link-text'>{t('goPortal')}</div>
								</a>
							</div>
						</div>
					</div>
					<NotPhone>
						<StarsRightIcon className='stars-right' />
					</NotPhone>
				</div>
				<div ref={this.props.onMount} className='static-page'>
					{content}
				</div>
				<MetaInfo record={this.page} />
			</>
		);
	}
}
