import React from 'react';
import { observer } from 'mobx-react';
import store from 'client/store';
import { Select } from '@smartplatform/ui';
import { observable } from 'mobx';
import { changeLanguage, t } from 'i18next';

@observer
export class Language extends React.Component {
	@observable value = store.local.languageId;
	onLanguageChange = (languageId) => {
		this.value = languageId;
		store.local.languageId = this.value;
		store.local.save();
		store.ui.onClosePortalMap();

		const currentLanguage = store.ui.languages.find((r) => r.id === languageId);
		changeLanguage(currentLanguage?.code?.toLowerCase() || 'ru');
		setTimeout(() => {
			store.reloadApp(); // обновляем все приложение с задержкой
		}, 0);
	};
	render() {
		const items = store.ui.languages.map(({ name, id, code }) => ({ label: name, value: id, code }));
		return <Select size='small' items={items} value={this.value} className='language' isRequired onChange={this.onLanguageChange} noTotal noSearch />;
	}
}
