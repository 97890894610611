import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Switch, Link, Route, withRouter, matchPath } from 'react-router-dom';

import store from '../../store';
import './style.scss';

@withRouter
export default class Links extends React.Component {
	static propTypes = {
		className: PropTypes.string,
		linksOnly: PropTypes.bool,
	};

	static defaultProps = {
		linksOnly: false,
	};

	render() {
		const items = [];
		const children = [];

		let activeItem = 0;

		React.Children.forEach(this.props.children, (child, i) => {
			if (child && child.props && child.props.isItem) {
				const { path, exact } = child.props;
				const match = matchPath(store.route.path, { path, exact });
				if (match) activeItem = i;
				// console.log('>', path, match);
				items.push(child.props);
			} else {
				children.push(child);
			}
		});

		const _items = items.map((item, i) => (
			<li key={i} className={'route-menu-item' + (i === activeItem ? ' active' : '')}>
				<Link to={item.path} alt={item.title}>
					{item.title}
				</Link>
			</li>
		));

		const routes = items.map((item, i) => <Route key={i} path={item.path} exact={item.exact} render={() => item.children} />);

		const className = classNames('route-links-page', this.props.className);

		return (
			<div className={className}>
				<ul className='route-links-container' ref={this.onMount}>
					{_items}
				</ul>
				<div className='route-links-content'>
					{!this.props.linksOnly && <Switch>{routes}</Switch>}
					{children}
				</div>
			</div>
		);
	}
}
