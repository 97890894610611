import React, { Component } from 'react';
import store from 'client/store';
import { observer } from 'mobx-react';
import { Tabs, Tab } from '@smartplatform/ui';
import AllSitesStore from './store';
import {
	SubmitSearchInput,
	SectionCard,
	BasicSiteCard,
	PopularSitesSections,
	SecondaryLayout,
	AutoAnimate,
	Desktop,
	Mobile,
	NotPhone,
	Phone,
} from 'components';
import { Link } from 'react-router-dom';
import t from 'i18n';
import './style.scss';
import MediaQuery from 'react-responsive';
import { mediaQueryBreakPoints } from 'constants';

@observer
export default class AllSites extends Component {
	constructor(props) {
		super(props);
		this.store = new AllSitesStore();
	}

	render() {
		const { onSiteTypeChange, records, onSearchSubmit } = this.store;

		return (
			<SecondaryLayout backgroundPicture='mountains'>
				<div className='all-sites-container'>
					<SectionCard title={t('allSites.lineTitle')} icon='earth-arrow' className='all-sites'>
						{store.ui.main3sites.length > 0 && (
							<div className='main-sites'>
								{store.ui.main3sites.map((site) => (
									<BasicSiteCard key={site.id} record={site} />
								))}
							</div>
						)}
						<div className='link-block'>
							<span className='name'>{t('allSites.goToDepartment')}</span>
							<Link className='link' to='/all-sites' alt={t('allSites.allPortalSites')}>
								{t('allSites.allPortalSites')}
							</Link>
						</div>
						<NotPhone>
							<SubmitSearchInput onSubmit={onSearchSubmit} placeholder={t('searchInput.placeholderAllSites')} />
						</NotPhone>
						<Phone>
							<SubmitSearchInput
								onSubmit={onSearchSubmit}
								placeholder={t('searchInput.placeholderAllSites')}
								mobileIconBtn={true}
								className='submit-search-input-with-icon'
							/>
						</Phone>
						<Tabs type='manual' className='tabs'>
							{[{ name: t('all') }, ...store.ui.siteTypes].map((type) => {
								return <Tab title={type.name} key={type.name} onClick={() => onSiteTypeChange(type)} />;
							})}
						</Tabs>

						<AutoAnimate className='list'>
							{records.map((site) => (
								<BasicSiteCard key={site.id} record={site} />
							))}
						</AutoAnimate>
					</SectionCard>

					<PopularSitesSections className='popular-sites' />
				</div>
			</SecondaryLayout>
		);
	}
}
