import React from 'react';
import { observable } from 'mobx';
import { Provider, observer } from 'mobx-react';
import './style.scss';
import NewsStore from 'client/pages/main/news/store';
import { Loader, Tab, Tabs } from '@smartplatform/ui';
import { t } from 'i18next';
import Articles from 'components/news/Articles';
import { SmallArticle, AdditionalBanner } from 'components';
import newsIcon from 'client/img/icon-news.svg?url';
import Filters from './filters/Filters';
import { Button } from 'components';
import store from 'client/store';
import More from 'client/pages/news/article/More';
import { SvgIcon } from 'components/svgIcon';
import { getModelWhere } from 'helpers';

@observer
export default class News extends React.Component {
	@observable isLoading = true;
	@observable appurtenance = [];

	constructor(props) {
		super(props);
		this.store = new NewsStore();
		this.newsAppurtenance();
	}

	newsAppurtenance = async () => {
		this.isLoading = true;
		const where = getModelWhere(store.model.ViewArticle, false);
		where.and.push({ showOnMain: true });
		const appurtenances = await store.model.ViewArticleAppurtenance.find({
			fields: ['id', 'name'],
			include: [{ relation: 'articles', scope: { fields: ['id', 'sitesIds', 'organizationId', 'languageId', 'appurtenanceId', 'publish'], where, limit: 1 } }],
		});
		this.appurtenance = appurtenances.filter((r) => !!r.articles().length);
		this.isLoading = false;
	};

	goToNews = () => {
		store.route.push({ path: `/news` });
	};

	render() {
		const { setTab, isLoadingMain, smallTab, onChangeFilter, setTabSmallArticle, recordsSmall, records } = this.store;

		if (this.isLoading) return null;

		return (
			<Provider store={this.store}>
				<div className='news-main'>
					<div className='top'>
						<div className='left'>
							<span>{t('Главное')}</span>
							<SvgIcon name='main-news' />
							<div className='line' />
						</div>
						<div className='right'>
							<span>{t('Последние новости')}</span>
							<div className='line' />
						</div>
					</div>
					<div className='news-nav'>
						<Tabs type='manual' className='tabs p1'>
							<Tab title={t('news.last')} onClick={() => setTab('all')} />
							{this.appurtenance &&
								this.appurtenance.map((tab) => {
									if (!tab) return;
									return <Tab title={tab.name} key={tab.id} onClick={() => onChangeFilter('appurtenance', tab)} />;
								})}
						</Tabs>
						<Filters />
					</div>
					<div className='news-container'>
						<div className='left'>
							{isLoadingMain ? (
								<Loader size={20} />
							) : (
								<>
									{records.length ? <Articles articles={records.slice(0, 8)} /> : t('article.notFound')}
									{!!records.length && (
										<Button color='grey' onClick={this.goToNews} size='large' className>
											{t('news.showNews')}
										</Button>
									)}
								</>
							)}
						</div>
						<div className='right'>
							<AdditionalBanner />
							<Tabs type='manual'>
								<Tab title={t('news.last')} onClick={() => setTabSmallArticle('last')} />
							</Tabs>
							{smallTab === 'last' && <More key={'more-' + recordsSmall[0]?.id} className='more-articles' />}
						</div>
					</div>
				</div>
			</Provider>
		);
	}
}
