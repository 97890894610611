import React from 'react';
import { DEFAULT_STRUCTURE_STYLE } from 'root/admin/pages/organization/constants';

export default class ItemStructure extends React.Component {
	render() {
		const { record, level } = this.props;

		const children = record.children;

		if (!record) return null;

		return (
			<div className='wrapper-item-structure'>
				<div className='info' style={{ background: `${record.style?.background || DEFAULT_STRUCTURE_STYLE.background} !important` }}>
					{record.link ? (
						<a
							href={record.link}
							className='head-item-structure p2'
							style={{ color: `${record.style?.colorText || DEFAULT_STRUCTURE_STYLE.colorText} !important` }}
							alt={record.name}
						>
							{record.name}
						</a>
					) : (
						<span className='head-item-structure p2' style={{ color: `${record.style?.colorText || DEFAULT_STRUCTURE_STYLE.colorText} !important` }}>
							{record.name}
						</span>
					)}
					<span className='desc-item-structure p3' style={{ color: `${record.style?.colorText || DEFAULT_STRUCTURE_STYLE.colorText} !important` }}>
						{record.value}
					</span>
				</div>
				{!!children.length && level > 1 && (
					<div className='wrapper-children'>
						{children.map((children) => (
							<ItemStructure record={children} level={level + 1} />
						))}
					</div>
				)}
			</div>
		);
	}
}
