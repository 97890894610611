import React from 'react';
import PropTypes from 'prop-types';

const MOBILE = '(max-width: 640px)';
const TABLET = '(max-width: 1247px)';
const DESKTOP = '(min-width: 1248px)';
const QUALITY = 80;

/* Picture используется для ресайза и сжатия картинок под определенные брейпоинты.
	к примеру, если нужно заресайзить картинку баннер во весь экран на главной , опускаем src, className ,desktopWidth 1920, tabletWidth 1200, mobileWidth 767
	или же если нужно заресайзить какое-то изображение в карточке, но сразу для всех брейпоинтов, то опускаем только width или hight
*/
export const Picture = ({
	className,
	pictureClassName,
	src,
	desktopWidth,
	desktopHeight,
	tabletWidth,
	tabletHeight,
	mobileWidth,
	mobileHeight,
	width,
	height,
	quality,
	alt,
}) => {
	if (!src) return;
	const closureKey = (key) => () => ++key;
	const getKey = closureKey(0);
	const children = [];
	const getSrcset = ({ type, width, height }) => {
		const queryObject = [{ [type]: { quality: quality || QUALITY }, resize: { width, height } }];
		if (!queryObject[0].resize.width && !queryObject[0].resize.height) delete queryObject[0].resize;
		return src + '?image=' + JSON.stringify(queryObject);
	};

	if (mobileWidth || mobileHeight) {
		children.push(
			<source type='image/webp' media={MOBILE} srcSet={getSrcset({ width: mobileWidth, height: mobileHeight, type: 'webp' })} key={getKey()} />,
			<source type='image/jpeg' media={MOBILE} srcSet={getSrcset({ width: mobileWidth, height: mobileHeight, type: 'jpeg' })} key={getKey()} />
		);
	}

	if (tabletWidth || tabletHeight) {
		children.push(
			<source type='image/webp' media={TABLET} srcSet={getSrcset({ width: tabletWidth, height: tabletHeight, type: 'webp' })} key={getKey()} />,
			<source type='image/jpeg' media={TABLET} srcSet={getSrcset({ width: tabletWidth, height: tabletHeight, type: 'jpeg' })} key={getKey()} />
		);
	}

	if (desktopWidth || desktopHeight) {
		children.push(
			<source type='image/webp' media={DESKTOP} srcSet={getSrcset({ width: desktopWidth, height: desktopHeight, type: 'webp' })} key={getKey()} />,
			<source type='image/jpeg' media={DESKTOP} srcSet={getSrcset({ width: desktopWidth, height: desktopHeight, type: 'jpeg' })} key={getKey()} />
		);
	}

	children.push(
		<source type='image/webp' srcSet={getSrcset({ width, height, type: 'webp' })} key={getKey()} />,
		<source type='image/jpeg' srcSet={getSrcset({ width, height, type: 'jpeg' })} key={getKey()} />
	);

	return (
		<picture className={pictureClassName}>
			{children}
			<img src={src + `?v=${Date.now().toString()}`} alt={alt || 'image'} className={className} />
		</picture>
	);
};

Picture.propTypes = {
	className: PropTypes.string,
	src: PropTypes.string.isRequired,
	desktopWidth: PropTypes.number,
	desktopHeight: PropTypes.number,
	tabletWidth: PropTypes.number,
	tabletHeight: PropTypes.number,
	mobileWidth: PropTypes.number,
	mobileHeight: PropTypes.number,
	width: PropTypes.number,
	height: PropTypes.number,
};
