import React from 'react';
import { observer } from 'mobx-react';
import './style.scss';
import emptyVideo from 'img/media/empty-video.svg?url';
import emptyAudio from 'img/media/empty-audio.svg?url';
import emptyPhoto from 'img/media/empty-photo.svg?url';
import { formatTime } from 'client/helpers';

@observer
export default class MediaCard extends React.Component {
	getImage = (record) => {
		const { type } = this.props;
		switch (type) {
			case 'video': {
				if (record?.image) {
					return <img src={record.downloadFile('image')} alt={record.name} />;
				}
				return <img src={emptyVideo} alt={record.name} />;
			}
			case 'audio': {
				if (record?.image) {
					return <img src={record.downloadFile('image')} alt={record.name} />;
				}
				return <img src={emptyAudio} alt={record.name} />;
			}
			case 'photo': {
				if (record.media().length) {
					return <img src={record.media()[0].downloadFile('image')} alt={record.name} />;
				}
				return <img src={emptyPhoto} alt={record.name} />;
			}
			default: {
				return null;
			}
		}
	};

	render() {
		const { record, onClick } = this.props;

		return (
			<div className='media-card' key={record.id} onClick={onClick}>
				<div className='card-image'>{this.getImage(record)}</div>
				<div className='card-description'>
					<div className='card-name'>{record.name}</div>
					<div className='card-author'>{record.author}</div>
					<div className='card-view-date'>
						<span>{record.viewsCount} просмотров</span>
						<span>{record.date ? ` | ${formatTime(record.date)}` : ''}</span>
					</div>
				</div>
			</div>
		);
	}
}
