import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'components/button';
import AppealIcon from 'img/appeal.svg';
import LoginIcon from 'img/login.svg';
import { t } from 'i18next';

const Copyright = () => (
	<div className='copyright'>
		<div className='footer-bottom-buttons'>
			<Link to='/citizens-appeals' alt={t('copyright.sendAppeal')}>
				<Button color='white' prependIcon={<AppealIcon />}>
					{t('copyright.sendAppeal')}
				</Button>
			</Link>
			{/*<Button color='deep-blue' appendIcon={<LoginIcon />}>
				{t('copyright.login')}
			</Button>*/}
		</div>
		<div>
			{new Date().getFullYear()} {t('copyright.allRightsReserved')}
		</div>
		<div className='policy-agreement'>
			<Link to='/policy' alt={t('copyright.policy')}>
				{t('copyright.policy')}
			</Link>
		</div>
	</div>
);

export default Copyright;
