import React from 'react';
import appStore from 'client/store';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import './WidgetsPOS.scss';

const DEFAULT_WRAPPER_MAX_WIDTH = 1200;
const DEFAULT_WRAPPER_MIN_HEIGHT = 300;

@observer
export class WidgetsPOS extends React.Component {
	@observable widgets = [];

	constructor(props) {
		super(props);
		this.fetchWidgets();
	}

	fetchWidgets = async () => {
		this.widgets = await appStore.model.WidgetPOS.find({
			where: { siteId: appStore.ui.site.id, isActive: true, languageId: appStore.local.languageId, code: { neq: null } },
		});
	};

	renderWidget = (content) => {
		const srcScripts = [];
		const scriptTags = [];

		const regex = /<script\b[^>]*>([\s\S]*?)<\/script>/gi;

		// Получаем все теги и добавляем их в массив
		let match;
		while ((match = regex.exec(content)) !== null) {
			if (/src\s*=\s*["'][^"']*["']/i.test(match[0])) {
				srcScripts.push(match[0]);
			} else {
				scriptTags.push(match[0]);
			}
		}

		this.loadScriptsSequentially(srcScripts, () => {
			// Все внешние скрипты загружены, теперь добавляем инлайн-скрипты
			scriptTags.forEach((scriptString) => {
				const scriptElement = this.createScriptFromString(scriptString);
				document.body.appendChild(scriptElement);
			});
			if (window.widget && typeof window.widget === 'function') window.widget();
		});

		// Удаляем все теги <script> из контента
		return content.replace(regex, '');
	};

	// Функция для создания элемента script из строки
	createScriptFromString = (scriptString) => {
		const script = document.createElement('script');

		// Создаем временный элемент для парсинга HTML
		const temp = document.createElement('div');
		temp.innerHTML = scriptString;
		const originalScript = temp.querySelector('script');

		if (!originalScript) return script;

		// Копируем все атрибуты из оригинального скрипта
		for (const attr of originalScript.attributes) {
			script.setAttribute(attr.name, attr.value);
		}

		// Если это инлайн-скрипт (без src), копируем содержимое
		if (!originalScript.hasAttribute('src') && originalScript.textContent) {
			script.textContent = originalScript.textContent;
		}

		return script;
	};

	// Функция для последовательной загрузки скриптов
	loadScriptsSequentially = (scripts, callback) => {
		let index = 0;

		const loadNext = () => {
			if (index >= scripts.length) {
				callback();
				return;
			}

			const scriptString = scripts[index++];
			const scriptElement = this.createScriptFromString(scriptString);

			scriptElement.onload = scriptElement.onerror = () => {
				loadNext();
			};

			document.body.appendChild(scriptElement);
		};

		loadNext();
	};

	render() {
		const wrapperStyle = {
			maxWidth: appStore.mergedConfig.posMaxWidth ? `${appStore.mergedConfig.posMaxWidth}px` : `${DEFAULT_WRAPPER_MAX_WIDTH}px`,
			minHeight: appStore.mergedConfig.posMinHeight ? `${appStore.mergedConfig.posMinHeight}px` : `${DEFAULT_WRAPPER_MIN_HEIGHT}px`,
		};

		const { widgets, renderWidget } = this;

		if (!widgets.length) return null;

		return (
			<div className='WidgetsPOS' style={wrapperStyle}>
				{widgets.map((widget) => {
					const style = {};
					if (widget.maxWidth) style.maxWidth = `${widget.maxWidth}%`;
					return <div className='WidgetsPOS-item' style={style} dangerouslySetInnerHTML={{ __html: renderWidget(widget.content) }}></div>;
				})}
			</div>
		);
	}
}
