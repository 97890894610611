import React, { Component } from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Picture } from 'components';
import emptyPicture from 'client/img/management-empty-pic.jpg?url';
import store from 'client/store';

@observer
export class ManagementCard extends Component {
	@observable person = null;

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		this.person = this.props.person;
	};

	openPersonPage = async () => {
		store.route.push({ path: `/managements/${this.person.id}` });
	};

	render() {
		return (
			<div className='management-person-card' onClick={this.openPersonPage}>
				{this.person.image ? (
					<Picture src={this.person.downloadFile('image')} width={180} height={240} className='management-person-card-pic' alt={this.person.fio} />
				) : (
					<Picture src={emptyPicture} width={180} height={240} className='management-person-card-pic' alt={this.person.fio} />
				)}
				<div className='management-person-card__text-wrapper'>
					<div className='management-person-card__title h6'>{this.person.fio}</div>
					<div className='management-person-card__position p2'>{this.person.position}</div>
				</div>
			</div>
		);
	}
}
