import { observable } from 'mobx';
import store from 'client/store';
import { getModelWhere } from 'helpers';
import { endOfDay, endOfMinute } from 'date-fns';

const PER_PAGE = 9;
const PER_PAGE_SMALL = 6;
export default class NewsStore {
	@observable filter = {
		county: null,
		topic: null,
		appurtenance: null,
	};

	@observable records = [];
	@observable eventsByDate = null;
	@observable isLoadingMain = true;
	@observable isLoadingSmall = true;
	@observable smallTab = 'last';
	@observable recordsSmall = [];

	constructor() {
		this.init();
	}

	init = async () => {
		await this.fetchMain();
		await this.fetchSmall();
	};

	fetchMain = async () => {
		this.isLoadingMain = true;
		const where = getModelWhere(store.model.ViewArticle, false);
		where.and.push({ publishAt: { lt: endOfMinute(new Date()) } });
		// В запросе по вкладкам тоже поправить showOnMain, если будет затрагиваться
		where.and.push({ showOnMain: true });
		this.filter.county && where.and.push({ countyId: this.filter.county.id });
		this.filter.topic && where.and.push({ topicId: this.filter.topic.id });
		this.filter.appurtenance && where.and.push({ appurtenanceId: this.filter.appurtenance.id });

		this.records = await store.model.ViewArticle.find({
			where,
			include: ['labels', 'magazine', 'county', 'topics', 'category', 'appurtenance'],
			limit: PER_PAGE,
			order: ['priority desc', 'publishAt desc'],
		});
		this.isLoadingMain = false;
	};

	fetchSmall = async () => {
		this.isLoadingSmall = true;
		const where = getModelWhere(store.model.ViewArticle, false);
		where.and.push({ publishAt: { lt: endOfMinute(new Date()) } });
		this.filter.county && where.and.push({ countyId: this.filter.county.id });

		this.recordsSmall = await store.model.ViewArticle.find({
			where,
			include: ['labels', 'magazine', 'county', 'topics', 'category', 'appurtenance'],
			limit: PER_PAGE_SMALL,
			order: ['priority desc', 'publishAt desc'],
		});

		this.isLoadingSmall = false;
	};

	setTab = async (tab) => {
		this.tab = tab;
		if (tab === 'all') {
			this.filter.appurtenance = null;
		}
		await this.fetchMain();
	};

	setTabSmallArticle = async (smallTab) => {
		this.smallTab = smallTab;
		await this.fetchSmall();
	};

	onChangeFilter = (field, record) => {
		this.filter[field] = record;
		this.fetchMain();
		if (field === 'county') this.fetchSmall();
	};
}
