import React from 'react';
import { Link } from 'react-router-dom';
import './style.scss';
import store from 'client/store';
import { t } from 'i18next';

export const PageNotFound = () => (
	<div className='page-not-found'>
		<span className='page'>{t('pageDevelopment')}</span>
		{store.ui.site.published && (
			<Link to='/' className='link' alt={t('backToMainPage')}>
				{t('backToMainPage')}
			</Link>
		)}
	</div>
);
