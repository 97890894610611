import React, { Component } from 'react';
import './style.scss';
import StarsLeftIcon from 'img/pattern-stars-left2.svg';
import SearchInput from './SearchInput';
import StarsRightIcon from 'img/pattern-stars-right.svg';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import AllSitesIcon from 'img/icon-all_sites-grey.svg';
import ArrowLeft from 'img/icon-arrow_left-black.svg';
import { Breadcrumbs, NotPhone } from 'components';

import classNames from 'classnames';
import store from 'client/store';
import t from 'i18n';

@observer
export default class SearchPage extends Component {
	@observable mainSite = '';

	constructor(props) {
		super(props);
		this.init();
	}
	init = async () => (this.mainSite = (await store.model.Site.find({ where: { isMain: true, publish: true } }))?.[0]);

	render() {
		const { headerName, noSearch, crumbs, toChapter, searchRightSide: SearchRightSide } = this.props;
		const searchPage = classNames('search-page', { 'search-page-with-crumbs': crumbs });

		// Проверка на тип пропса и его рендер в зависимости от нужного типа
		let searchRightSideContent;
		// Проверяем тип пропса searchRightSide
		if (typeof SearchRightSide === 'function') {
			// Если searchRightSide — это функция, вызываем её
			searchRightSideContent = SearchRightSide();
		} else if (React.isValidElement(SearchRightSide)) {
			// Если searchRightSide — это React-элемент, используем его как есть
			searchRightSideContent = SearchRightSide;
		} else if (SearchRightSide && typeof SearchRightSide === 'object') {
			// Если searchRightSide — это объект (например, компонент), рендерим его
			searchRightSideContent = <SearchRightSide />;
		}

		return (
			<div className={searchPage}>
				<NotPhone>
					<StarsLeftIcon className='stars-left' />
				</NotPhone>
				<div className='content'>
					{crumbs && <Breadcrumbs crumbs={crumbs} />}
					<div className='search-title-line'>
						<span className='h1 header'>{headerName}</span>
						<div className='right templates-tabs'>
							{toChapter && (
								<a href={'https://' + store.ui.site?.domain + toChapter} className='portal-link' alt={t('searchPage.chapter')}>
									<ArrowLeft />
									<div className='link-text'>{t('searchPage.chapter')}</div>
								</a>
							)}
							<a href={'https://' + this.mainSite?.domain} className='portal-link' alt={t('goPortal')}>
								<AllSitesIcon />
								<div className='link-text'>{t('goPortal')}</div>
							</a>
						</div>
					</div>
					<div className='search-line'>
						{!noSearch && <SearchInput {...this.props} />}
						{searchRightSideContent && searchRightSideContent}
					</div>
				</div>
				<NotPhone>
					<StarsRightIcon className='stars-right' />
				</NotPhone>
			</div>
		);
	}
}
