import React from 'react';
import PropTypes from 'prop-types';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Link, withRouter } from 'react-router-dom';
import { Breadcrumbs, SocialSinglePage } from 'components';
import More from '../news/article/More';
import { formatDate } from 'client/helpers';
import store from 'client/store';
import './style.scss';
import { Labels } from '../../components';
import { getRoleNames } from 'client/helpers';
import { PageNotFound } from '../404';

import TelegramIcon from '-!svg-react-loader?name=Icon!client/img/social/icon-telegram-big.svg';
import MailIcon from '-!svg-react-loader?name=Icon!client/img/social/icon-mail.svg';
import ArrowLeft from '-!svg-react-loader?name=Icon!client/img/icon-arrow_left-black.svg';
import ArrowRight from '-!svg-react-loader?name=Icon!client/img/icon-arrow_right.svg';

@withRouter
@observer
export default class Article extends React.Component {
	static propTypes = {
		comments: PropTypes.bool,
		social: PropTypes.bool,
		more: PropTypes.bool,
	};

	static defaultProps = {
		comments: true,
		social: true,
		more: true,
	};

	@observable record = null;
	@observable error = null;
	@observable isLoading = true;
	@observable isAdmin = false;

	constructor(props) {
		super(props);
		this.init();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.match.params.id !== prevProps.match.params.id || this.props.id !== prevProps.id) {
			this.init();
		}
	}

	init = async () => {
		this.id = this.props.id || parseInt(this.props.match.params.id);
		const roleNames = await getRoleNames();
		this.isAdmin = roleNames.includes('admin') || (store.model.user && store.model.user.magazineId === store.ui.magazine.id);
		if (this.id) {
			try {
				this.record = await store.model.ViewArticle.findById(this.id, {
					include: [
						{ relation: 'category', scope: { fields: ['id', 'name', 'path', 'fields'] } },
						{ relation: 'magazine', scope: { fields: ['id', 'name', 'countyId'], include: ['county'] } },
						{ relation: 'topics' },
					],
				});
			} catch (e) {
				this.error = e.message;
			}
		} else {
			this.error = 'Страница не найдена';
		}
		this.isLoading = false;
	};

	render() {
		if (this.isLoading) return <div className='article-page' />;
		if (this.error) return <PageNotFound />;

		const { comments, social, more } = this.props;

		const crumbs = [];
		// if (this.record.magazine && this.record.magazine.county) crumbs.push({ title: this.record.magazine.county.name, path: `/county/${this.record.magazine.countyId}` });
		if (this.record.categoryId !== 1) {
			crumbs.push({ title: this.record.category.name, path: '/' + this.record.category.path });
		} else {
			crumbs.push({
				title: this.record.countyId !== 1 ? 'Новости района' : 'Новости',
				path: '/' + this.record.category.path,
			});
		}
		crumbs.push({ title: this.record.title });

		const fields = this.record.category.fields || {};

		const url = location.origin;

		return (
			<div className='article-page-container'>
				<div className='article-page'>
					<Breadcrumbs key={'crumbs-' + this.record.id} crumbs={crumbs} />
					{fields.title && fields.title.active && <div className='title h1'>{this.record.title}</div>}

					{this.isAdmin && (
						<div className='link'>
							<a target='_blank' href={`/admin/articles/${this.record.id}`} alt='Редактировать новость'>
								Редактировать новость
							</a>
						</div>
					)}
					<div className='info'>
						<div className='left'>
							{fields.publishAt && fields.publishAt.active && <span className='date p4'>{formatDate(this.record.publishAt, 'dd.MM.yyyy')}</span>}
							{((fields.date0 && fields.date0.active) || (fields.date0 && fields.date0.active)) && (
								<span className='date p4'>
									{this.record.date0 ? formatDate(this.record.date0) : ''}
									{this.record.date0 && this.record.date1 ? ' - ' : ''}
									{this.record.date1 ? formatDate(this.record.date1) : ''}
								</span>
							)}
							{/*{fields.magazine && fields.magazine.active && this.record.magazine && <Link to={`/magazines/${this.record.magazineId}`} className="magazine">{this.record.magazine.name}</Link>}*/}
							{/* {fields.magazine && fields.magazine.active && this.record.magazine && (
							<span>{this.record.magazine.name}</span>
						)} */}
							<span className='date p4'>Рубрика</span>
						</div>
						{/* <div className='right'>
						<ViewsCountActive record={this.record} />
					</div> */}
					</div>
					<div className='article-container'>
						<div className='article'>
							{fields.topic && fields.topic.active ? (
								<div className='topics'>
									<Topic record={this.record} />
								</div>
							) : null}
							{this.record.image && (
								<div className='image'>
									<img src={this.record.downloadFile('image') + '?image=[{"resize":{"width":960}}]'} alt={this.record.title} />
								</div>
							)}
							<div className='image-signature p3'>Подпись или автор фото</div>

							<div className='content'>
								{this.record.subtitle && (
									<div className='subtitle'>
										<p>
											<strong>{this.record.subtitle}</strong>
										</p>
									</div>
								)}
								<div className='text' dangerouslySetInnerHTML={{ __html: this.record.text }} />

								{fields.labels && fields.labels.active ? (
									<Labels recordId={this.record.id} categoryId={this.record.categoryId} categoryPath={this.record.categoryPath} />
								) : null}

								<div className='footer'>
									{this.record.author && <div className='author'>{this.record.author}</div>}
									<div className='social-share'>
										<div>Поделиться</div>
										{social && (
											<div className='social-bottom'>
												<SocialSinglePage title={this.record.title} />
											</div>
										)}
									</div>
								</div>
							</div>

							<div className='more-container'>
								<div className='related-news'>
									<span className='p4'>Новости по теме</span>
									<span className='line'></span>
								</div>
								{more && <More key={'more-' + this.record.id} currentArticle={this.record} />}
							</div>
						</div>
					</div>
					{/* {comments && <Comments key={'comments-' + this.record.id} record={this.record} />} */}

					<div className='share'>
						<div className='share-telegram'>
							<TelegramIcon className='telegram-icon' />
							<span className='p1'>Получать оперативные новости в официальном канале</span>
						</div>
						<div className='share-mail'>
							<MailIcon className='mail-icon' />
							<span className='p1'>Подписаться на рассылку главных новостей сайта</span>
						</div>
					</div>
					<div className='back-forward'>
						<div className='back'>
							<ArrowLeft className='arrow-left' />
							<span className='p4'>Вернуться в раздел</span>
						</div>
						<div className='forward'>
							<ArrowRight className='arrow-right' />
							<span className='p4'>Ставка на опережение: зачем продлена и расширена программа госгарантий по ОМС</span>
						</div>
					</div>
				</div>

				{/* SideBar */}
				<div className='sidebar'>
					<div className='sidebar-news'>
						<span className='p4'>Интересно</span>
						<span className='line'></span>
					</div>
					{more && <More key={'more-' + this.record.id} currentArticle={this.record} />}
				</div>
			</div>
		);
	}
}

const Topic = ({ record }) => {
	return record.topics().length
		? record.topics().map((topic, i) => {
				return (
					<Link to={`/${record.categoryPath}/topic/${topic.name}`} alt={topic.name}>
						<div key={i} className='topic'>
							{' '}
							{topic.name}{' '}
						</div>
					</Link>
				);
			})
		: null;
};
