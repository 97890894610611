import React from 'react';
import { Filters } from './DocumentsFilters';
import { Provider, observer } from 'mobx-react';
import { Documents as _Documents } from './Documents';
import t from 'i18n';
import './documents.scss';
import DocumentsStore from './documentsStore';
import { SearchPage } from '../../components/search-page';
import { observable } from 'mobx';
import store from 'client/store';

@observer
export default class Documents extends React.Component {
	@observable isLoading = true;
	npaLinkRecord = null;

	constructor(props) {
		super(props);
		this.store = new DocumentsStore(new URLSearchParams(this.props.location.search).get('search'), this.props.match.params?.subjectCode);
		this.init();
	}

	init = async () => {
		const [record] = await store.model.ArchiveNPALink.find({
			where: { siteId: store.ui.site.id, isActive: true, languageId: store.local.languageId },
			limit: 1,
		});

		this.npaLinkRecord = record;
		this.isLoading = false;
	};

	componentDidUpdate(prevProps) {
		const { subjectCode } = this.props.match.params;
		if (prevProps.match.params?.subjectCode !== subjectCode) {
			if (this.props.match.url === '/documents') {
				this.store.subjectCode = null;
				this.store.subject = null;
			}
			this.store.init(subjectCode);
		}
	}

	render() {
		const { onChange, search, isLoading } = this.store;

		if (isLoading) return null;

		const searchRightSide = this.npaLinkRecord && (
			<a href={this.npaLinkRecord.link} className='archive-npa-link' alt={this.npaLinkRecord.text}>
				{this.npaLinkRecord.text}
			</a>
		);

		return (
			<Provider documentsStore={this.store}>
				<>
					<SearchPage
						headerName={t('document.registry')}
						placeholder={t('searchInput.placeholderDocumentsAcceptBody')}
						search={search}
						onChangeSearch={(r) => onChange('search')(r)}
						searchRightSide={searchRightSide}
					/>
					<div className='documents-page'>
						<Filters />
						<_Documents />
					</div>
				</>
			</Provider>
		);
	}
}
