import React from 'react';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';

import IconIpload from 'client/img/icon-upload.svg';
import ExternalLink from 'client/img/external-link.svg';
import Portal from 'client/img/portal.svg';
import './style.scss';
import { Button, Row } from '@smartplatform/ui';
import { LabelField, Phone } from 'components';
import { formatDate } from 'client/helpers';
import NoImage from 'img/events-no-image.svg';
import { SvgIcon } from 'components/svgIcon';
import { t } from 'i18next';

@withRouter
@observer
export default class Single extends React.Component {
	render() {
		const { event, onClosePopup } = this.props;
		const { date0, contacts, location, title, type, status, subtitle, organizer, county, executionType, text, createdAt, updatedAt, attachments, eventLink } =
			event;

		return (
			<div className='event-page'>
				<Phone>
					<div className='wrapper-back'>
						<div className='wrapper-control-back' onClick={onClosePopup}>
							<SvgIcon name='left-arrow' />
							<span>{t('back')}</span>
						</div>
					</div>
				</Phone>
				<div className='header'>
					<div className='image'>{event.image ? <img src={event.downloadFile('image') + '?image=[{"resize":{"width":400}}]'} alt={title} /> : <NoImage />}</div>
					<div className='text'>
						<div className='date'>
							{formatDate(date0, 'd MMMM, HH:mm')}, {location}
						</div>
						<div className='h6'>{title}</div>
						<div className='type'>{type?.name}</div>
						<div className='status'>{executionType?.name}</div>
						<div className='subtitle'>{subtitle}</div>
					</div>
				</div>
				<Row>
					<LabelField label='Организатор'>{organizer?.name}</LabelField>
					<LabelField label='Район'>{county?.name}</LabelField>
				</Row>
				<Row>
					<LabelField label='Статус'>{status?.name}</LabelField>
				</Row>
				<Row>
					<LabelField label='Описание'>
						<div className='text' dangerouslySetInnerHTML={{ __html: text }} />
					</LabelField>
				</Row>
				<Row>
					<LabelField label='Контакты'>{contacts ?? '-'}</LabelField>
				</Row>
				{!!attachments().length && (
					<Row>
						<LabelField label='Приложения'>
							<div className='applications'>
								{attachments().map((a) => (
									<a className='application' target='_blank' href={a.downloadFile('filename')} alt={a.filename} download>
										<div>{a.filename}</div>
										<IconIpload />
									</a>
								))}
							</div>
						</LabelField>
					</Row>
				)}
				<div className='dates'>
					<div className='published'>Опубликовано: {formatDate(createdAt, 'dd.MM.yyyy, HH:mm')}</div>
					<div className='last-update p3'>Последнее изменение: {formatDate(updatedAt, 'dd.MM.yyyy, HH:mm')}</div>
				</div>
				<div className='footer'>
					{/*<Button className='p2 request'>*/}
					{/*	Подать заявку <ExternalLink />*/}
					{/*</Button>*/}
					<Button className='p2 link' onClick={() => window.open(`mailto:${organizer?.email}`)} disabled={!organizer?.email}>
						Организатор
						<Portal />
					</Button>
					<Button className='p2 link' onClick={() => window.open(eventLink, '_blank', 'noreferrer')} disabled={!eventLink}>
						Сайт мероприятия
						<ExternalLink />
					</Button>
				</div>
			</div>
		);
	}
}
