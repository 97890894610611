import React, { Component } from 'react';
import t from 'i18n';
import './style.scss';

import family from 'client/img/popular-services/family.png?url';
import iconExternalLink from 'client/img/popular-services/external-link.svg?url';
import classNames from 'classnames';
import ExternalLink from 'helpers/externalLink';
class PopularServiceBigModules extends Component {
	render() {
		const { records, isLoading } = this.props;
		if (isLoading) return null;
		const className = classNames(`big-popular-services`);
		return (
			<>
				<div className='big-popular-services-container'>
					{records.length && (
						<div className={className}>
							<div className='big-popular-services-image'>
								<img src={family} alt={t('pupularServices')} />
							</div>
							<div className='big-popular-services-blocks'>
								<div className='big-block-column'>
									{records.map((rec) => (
										<ExternalLink to={rec.link} className='big-block' key={rec.id} alt={rec?.header}>
											{rec?.iconId ? (
												<img src={rec?.icon?.downloadFile('icon')} alt={rec?.header} className='big-icon-block' />
											) : <div className='empty'>{t('banner.noImage')}</div> ? (
												<img src={rec?.downloadFile('image')} alt={rec?.header} className='big-icon-block' />
											) : (
												<div className='empty'>{t('banner.noImage')}</div>
											)}
											<div className='big-text-block'>{rec?.header}</div>
											<img src={iconExternalLink} alt={rec?.header} className='big-external-link-block' />
										</ExternalLink>
									))}
								</div>
							</div>
						</div>
					)}
				</div>
			</>
		);
	}
}

export default PopularServiceBigModules;
