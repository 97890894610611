import { observable } from 'mobx';
import store from 'client/store';
import { getModelWhere } from 'helpers';

export default class SupportMeasureStore {
	@observable filter = {
		topic: null,
		label: null,
		search: '',
	};
	@observable tab = 'all';
	@observable records = [];
	@observable recordsPopular = [];
	@observable isLoading = false;
	@observable PER_PAGE = 6;
	@observable totalCountSupportMeasure = 0;

	constructor() {}

	init = async () => {
		this.isLoading = true;
		const { label, topic, search } = this.filter;
		const model = store.model.ViewSupportMeasure;
		const where = getModelWhere(model);
		topic && where.and.push({ topicId: topic.id });
		label && where.and.push({ labelIds: { ilike: `%${label.id}%` } });
		if (search) {
			where.and.push({ name: { ilike: `%${search}%` } });
		}
		this.records = await model.find({
			where,
			search: search ? search.trim() : undefined,
			include: [{ relation: 'labels', scope: { fields: ['id', 'name'] } }],
			order: this.filter.order,
			limit: this.PER_PAGE,
		});
		this.recordsPopular = await store.model.SupportMeasure.find({
			include: [{ relation: 'labels', scope: { fields: ['id', 'name'] } }],
			limit: this.PER_PAGE,
			order: 'createdAt desc',
		});
		this.totalCountSupportMeasure = this.records.totalCount;
		this.isLoading = false;
	};

	showMoreSupportMeasure = () => {
		this.PER_PAGE += 6;
		this.init();
	};

	setTab = async (tab) => {
		this.tab = tab;
		if (tab === 'all') {
			this.filter = {
				topic: null,
				label: null,
			};
		}
		await this.init();
	};

	onChangeFilter = (field, record) => {
		this.filter[field] = record;
		this.init();
	};
}
