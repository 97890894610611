import React, { Component } from 'react';
import './style.scss';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import store from 'client/store';
import ExternalLink from 'helpers/externalLink';

@observer
class AdditionalBanner extends Component {
	@observable record = null;
	@observable isLoading = true;

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		this.record = await store.model.AdditionalBanner.find({
			where: { siteId: store.ui.site && store.ui.site.id, organizationId: store.ui.organization && store.ui.organization.id },
			order: 'createdAt desc',
		});
		this.isLoading = false;
	};
	render() {
		if (this.isLoading) return '...';
		return (
			<>
				{this.record[0] && (
					<div className='additional-banner-container'>
						<div className='additional-banner-image'>
							{this.record[0].image ? (
								<img src={this.record[0].downloadFile('image')} alt={this.record[0].header} />
							) : (
								<div className='empty' style={{ background: this.record[0]?.colorBackground }}></div>
							)}
						</div>
						<div className='header-description-button-container'>
							<div className='additional-banner-header' style={{ color: this.record[0].colorText }}>
								{this.record[0].header}
							</div>
							<div className='additional-banner-description' style={{ color: this.record[0].colorText }}>
								{this.record[0].description}
							</div>
							<ExternalLink to={this.record[0].linkButton} alt={this.record[0].textButton}>
								<button className='additional-banner-button' style={{ color: this.record[0].colorTextButton, background: this.record[0].colorButton }}>
									{this.record[0].textButton}
								</button>
							</ExternalLink>
						</div>
					</div>
				)}
			</>
		);
	}
}

export default AdditionalBanner;
