import React from 'react';
import { Link } from 'react-router-dom';
import t from 'i18n';
import AllSitesIcon from 'client/img/icon-all_sites-grey.svg';
import './style.scss';

export const PortalLink = ({ path }) => (
	<Link to={path || '/'} className='portal-link' alt={t('goPortal')}>
		<AllSitesIcon />
		<div className='link-text'>{t('goPortal')}</div>
	</Link>
);
