import React, { Component } from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Picture, SocialSinglePage } from 'components';
import { SearchPage } from 'components/search-page';
import { formatDate } from 'helpers';
import emptyPicture from 'client/img/management-empty-pic.jpg?url';
import IconUpload from 'img/icon-upload.svg';

import t from 'i18n';
import store from 'client/store';
import './style.scss';
import TextWithDocuments from 'components/text-with-documents/TextWithDocuments';
import { MetaInfo } from 'components/meta-info';

@observer
export default class ManagementPersonPage extends Component {
	@observable record = null;
	@observable error = null;
	@observable attachments = [];

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		try {
			this.id = this.props.match.params.id;
			this.record = await store.model.Management.findById(this.id, { includes: ['attachments'] });
			this.attachments = await this.record.attachments();
		} catch (e) {
			this.error = e;
		}
	};

	render() {
		if (this.error || !this.record) return null;
		const {
			fio,
			position,
			dateOfBirth,
			education,
			biography,
			laborActivity,
			descriptionOfActivity,
			departmentalAwards,
			address,
			phone,
			email,
			schedule,
			preRegistrationPhone,
			assistant,
			supervisedDirections,
			legalActs,
			otherInformation,
		} = this.record;

		const renderTextBlock = (title, content, titleClass = 'p4') =>
			content ? (
				<div className='text-block-wrapper'>
					<div className={`text-block-title ${titleClass}`}>{title}</div>
					<div className='management-person-page-content-text p1'>
						<div dangerouslySetInnerHTML={{ __html: content }} />
					</div>
				</div>
			) : null;

		return (
			<>
				<SearchPage
					headerName={fio}
					toChapter={'/managements'}
					noSearch
					crumbs={[{ title: t('main') }, { title: t('managements.pageTitle') }, { title: position }, { title: fio }]}
				/>
				<div className='page-content-single management-person-page'>
					<div className='management-person-page-content'>
						<div className='management-person-page-image management-person-page-content-wrapper'>
							{this.record.image ? (
								<Picture src={this.record.downloadFile('image')} width={281} height={383} className='management-person-card-pic' alt={fio} />
							) : (
								<Picture src={emptyPicture} width={281} height={383} className='management-person-card-pic' alt={fio} />
							)}
							{renderTextBlock(t('managements.dateOfBirth'), dateOfBirth && formatDate(dateOfBirth), 'p4')}
							<div className='text-block-wrapper'>
								<div className='text-block-title p4'>{t('managements.contacts')}</div>
							</div>
							{renderTextBlock(t('managements.address'), address, 'p2')}
							{renderTextBlock(t('managements.phone'), phone, 'p2')}
							{renderTextBlock(t('managements.email'), email, 'p2')}
							{renderTextBlock(t('managements.schedule'), schedule, 'p2')}
							{renderTextBlock(t('managements.preRegistrationPhone'), preRegistrationPhone, 'p2')}
							{renderTextBlock(t('managements.assistant'), assistant, 'p2')}
						</div>
						<div className='management-person-page-content-wrapper'>
							<div className='text-block-wrapper'>
								<div className='management-person-page-content-text p1'>{position && <div dangerouslySetInnerHTML={{ __html: position }} />}</div>{' '}
							</div>
							{renderTextBlock(t('managements.education'), education, 'p4')}
							{renderTextBlock(t('managements.biography'), biography, 'p4')}
							{renderTextBlock(t('managements.laborActivity'), laborActivity, 'p4')}
							{renderTextBlock(t('managements.departmentalAwards'), departmentalAwards, 'p4')}
							{renderTextBlock(t('managements.descriptionOfActivity'), descriptionOfActivity, 'p4')}
							<TextWithDocuments content={supervisedDirections} title={t('managements.supervisedDirections')} />
							<TextWithDocuments content={otherInformation} title={t('managements.otherInformation')} />
							<TextWithDocuments content={legalActs} title={t('managements.legalActs')} />
							{!!this.attachments.length && (
								<div className='application-wrapper'>
									<div className='text-block-title p4'>{t('application')}</div>
									<div className='application'>
										{this.attachments.map((a) => (
											<a className='docs' target='_blank' href={a.downloadFile('filename')} download key={a.id} alt={a.filename}>
												<div className='file-name p2'>{a.filename}</div>
												<IconUpload />
											</a>
										))}
									</div>
								</div>
							)}
						</div>
						<div className='social-bottom'>
							<SocialSinglePage title={fio} />
						</div>
					</div>
					<MetaInfo record={this.record} />
				</div>
			</>
		);
	}
}
