import React from 'react';
import { observer } from 'mobx-react';
import './style.scss';
import store from '../../../store';
import Slider from 'react-slick';
import { observable } from 'mobx';
import emptyVideo from 'img/media/empty-video.svg?url';
import emptyAudio from 'img/media/empty-audio.svg?url';
import emptyPhoto from 'img/media/empty-photo.svg?url';
import Portal from 'img/icon-all_sites.svg';
import { Link } from 'react-router-dom';
import { getModelWhere } from 'helpers';
import t from 'i18n';
import { Desktop, Mobile, NotPhone, Phone } from 'components';

@observer
export default class MediaFeed extends React.Component {
	@observable records = true;
	@observable isLoading = true;

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		const model = store.model.ViewAlbum;
		const isMainShowAll = false;

		const where = getModelWhere(model, isMainShowAll);

		this.records = await model.find({
			where,
			include: ['media'],
			limit: 16,
			order: 'viewsCount desc',
		});
		this.isLoading = false;
	};

	getData = (record) => {
		const tab = record.type;

		switch (tab) {
			case 'video': {
				if (record?.image) {
					return <img src={record.downloadFile('image') + '?image=[{"resize":{"height": 167, "width":300}}]'} alt={record.name} />;
				}
				return <img src={emptyVideo} alt={record.name} />;
			}
			case 'audio': {
				if (record?.image) {
					return <img src={record.downloadFile('image') + '?image=[{"resize":{"height": 167, "width":300}}]'} alt={record.name} />;
				}
				return <img src={emptyAudio} alt={record.name} />;
			}
			case 'photo': {
				if (record.media().length) {
					return <img src={record.media()[0].downloadFile('image') + '?image=[{"resize":{"height": 167, "width":300}}]'} alt={record.name} />;
				}
				return <img src={emptyPhoto} alt={record.name} />;
			}
			default: {
				return null;
			}
		}
	};

	render() {
		if (this.isLoading) return null;
		const settings = {
			customPaging: function (i) {
				return <div className='paging'></div>;
			},
			dots: true,
			speed: 500,
			slidesToShow: this.records.length >= 4 ? 4 : this.records.length,
			slidesToScroll: 4,
		};

		const desktopContent =
			this.records.length > 4 ? (
				<Slider {...settings}>
					{this.records.map((x) => (
						<Link to={`/media/${x.id}`} key={x.id} className='slider-content' alt={x.name}>
							{this.getData(x)}
							<div>{x.name}</div>
						</Link>
					))}
				</Slider>
			) : (
				<div className='media-content'>
					{this.records.map((x) => (
						<Link to={`/media/${x.id}`} key={x.id} className='slider-content' alt={x.name}>
							{this.getData(x)}
							<div>{x.name}</div>
						</Link>
					))}
				</div>
			);

		const mobileContent = (
			<div className='media-content'>
				{this.records.map((x) => (
					<Link to={`/media/${x.id}`} key={x.id} className='slider-content' alt={x.name}>
						{this.getData(x)}
						<div>{x.name}</div>
					</Link>
				))}
			</div>
		);

		return (
			<div className='media-feed'>
				<div className='media-feed-header'>
					<span>{t('searchPage.album')}</span>
					<Link to={'/media'} alt={t('searchPage.goToSection')}>
						<Portal />
						{t('searchPage.goToSection')}
					</Link>
				</div>
				<NotPhone>{desktopContent}</NotPhone>
				<Phone>{mobileContent}</Phone>
			</div>
		);
	}
}
